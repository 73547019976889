define("@huvepharma/huvepharma-aviapp-components/services/regions", ["exports", "@ember/service", "@ember/object", "@ember/string", "@ember/array", "@ember/object/computed"], function (_exports, _service, _object, _string, _array, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }

  var SUBDIVISION_NAMES = 'subdivision_names';
  var REGIONS = 'regions';

  var _default = _service.default.extend({
    intl: (0, _service.inject)(),
    session: (0, _service.inject)(),
    defaultLocale: 'en',
    locale: (0, _object.computed)('session.me.locale', 'defaultLocale', function () {
      var locale = (0, _object.get)(this, 'session.me.locale') || (0, _object.get)(this, 'defaultLocale');
      return locale.split(['-']).pop();
    }),
    locales: (0, _computed.collect)('locale', 'defaultLocale'),

    /**
     * Gets the translation for a given key and type.
     * Steps taken:
     *  1. Check custom translations in current locale
     *  2. Check translated regions for current locale
     *  3. Check translated regions for default locale (en)
     *  4. Check translated regions for administrative languages
     *  5. Look for any possible translation
     *  6. Fallback to key
     * @param {String<Enum(SUBDIVISION_NAMES,REGIONS)>} type
     * @param {String} key
     * @param {String} administrativeLangs
     * @returns {String} translation
     */
    getTranslation: function getTranslation(type, key, administrativeLangs) {
      var _this = this;

      if ((0, _object.get)(this, 'intl').exists("".concat(type, "_").concat(key))) {
        return (0, _object.get)(this, 'intl').t("".concat(type, "_").concat(key)).string;
      }

      var langForTranslation = [].concat(_toConsumableArray((0, _object.get)(this, 'locales')), _toConsumableArray(administrativeLangs)).find(function (lang) {
        return (0, _object.get)(_this, "regionsTranslated.".concat(type, ".").concat(lang, ".").concat(key));
      });

      if (!langForTranslation) {
        langForTranslation = Object.keys((0, _object.get)(this, "regionsTranslated.".concat(type))).find(function (lang) {
          return Object.keys((0, _object.get)(_this, "regionsTranslated.".concat(type, ".").concat(lang))).includes(key);
        });
      }

      return (0, _string.capitalize)(langForTranslation ? (0, _object.get)(this, "regionsTranslated.".concat(type, ".").concat(langForTranslation, ".").concat(key)) : String(key));
    },

    /**
     * Given a countryCode, returns a map of translated
     * subdivision and regions.
     * @param {String} countryCode
     * @returns {Object<Subvidision,Regions[]>}
     */
    getRegionsForCountry: function getRegionsForCountry(countryCode) {
      var _this2 = this;

      var regionsForCountry = (0, _object.get)(this, "regions.".concat(countryCode));

      if (!regionsForCountry) {
        return null;
      }

      return Object.keys(regionsForCountry.regions).map(function (subdivisionKey) {
        return {
          label: _this2.getTranslation(SUBDIVISION_NAMES, subdivisionKey, regionsForCountry.lang),
          items: (0, _array.A)((0, _object.get)(regionsForCountry, "regions.".concat(subdivisionKey))).uniq().map(function (regionKey) {
            return {
              code: regionKey,
              label: _this2.getTranslation(REGIONS, regionKey, regionsForCountry.lang)
            };
          }).sort(function (a, b) {
            return a.label.localeCompare(b.label);
          })
        };
      }).sort(function (a, b) {
        return a.label.localeCompare(b.label);
      });
    }
  });

  _exports.default = _default;
});