define("@huvepharma/huvepharma-aviapp-components/templates/components/modal/date-picker", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "ovp9sBuR",
    "block": "[[[6,[39,0],null,[[\"show\",\"header\",\"headerText\",\"class\",\"headerCloseAction\"],[[33,1],true,[28,[37,2],[[33,3]],null],\"mod-modal-datepicker inline-datepicker\",[28,[37,4],[[30,0],\"closeAction\"],null]]],[[\"default\"],[[[[1,\"  \"],[10,0],[15,0,[29,[\"body \",[52,[33,6],\"body--large\"]]]],[12],[1,\"\\n\"],[41,[33,7],[[[1,\"      \"],[10,0],[14,0,\"message\"],[12],[1,[34,7]],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[10,0],[12],[1,\"\\n      \"],[1,[28,[35,8],null,[[\"renderContainer\",\"date\",\"changeDate\",\"closeAction\",\"closeOnSelectDate\",\"showToday\",\"markerClass\",\"unavailableClass\",\"minDate\",\"maxDate\",\"unavailableDates\",\"markerDate\",\"isDefaultEmpty\",\"todayLabel\"],[\".mod-modal-datepicker .body\",[33,9],[28,[37,4],[[30,0],\"changeCurrentSelectedDate\"],null],[28,[37,4],[[30,0],\"closeAction\"],null],[33,10],[33,6],\"picker__day--marker\",\"picker__day--unavailable\",[33,11],[33,12],[33,13],[33,14],[33,15],[28,[37,2],[\"app_label_today\"],null]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"legend\"],[12],[1,\"\\n    \"],[41,[33,16],[[[10,0],[14,0,\"legend-item\"],[12],[10,0],[14,0,\"marker-circle\"],[12],[13],[10,1],[12],[1,[28,[35,2],[[33,16]],null]],[13],[13]],[]],null],[1,\"\\n    \"],[41,[33,17],[[[10,0],[14,0,\"legend-item\"],[12],[10,0],[14,0,\"disabled-circle\"],[12],[13],[10,1],[12],[1,[28,[35,2],[[33,17]],null]],[13],[13]],[]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[41,[48,[30,1]],[[[1,\"    \"],[10,\"footer\"],[12],[1,\"\\n      \"],[10,2],[12],[18,1,[[33,9]]],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"]],[]]]]]],[\"&default\"],false,[\"modal/huve-base\",\"show\",\"t\",\"title\",\"action\",\"if\",\"showToday\",\"message\",\"form/huve-date-picker\",\"selectedDate\",\"closeOnSelectDate\",\"minDate\",\"maxDate\",\"visitDates\",\"markerDate\",\"isDefaultEmpty\",\"markerLegend\",\"disabledLegend\",\"has-block\",\"yield\"]]",
    "moduleName": "@huvepharma/huvepharma-aviapp-components/templates/components/modal/date-picker.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});