define("ember-validators/presence", ["exports", "@ember/debug", "@ember/utils", "ember-validators/utils/validation-error", "ember-validators/utils/unwrap-proxy"], function (_exports, _debug, _utils, _validationError, _unwrapProxy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validatePresence;

  /**
   *  @class Presence
   *  @module Validators
   */

  /**
   * @method validate
   * @param {Any} value
   * @param {Object} options
   * @param {Boolean} options.presence If true validates that the given value is not empty,
   *                                   if false, validates that the given value is empty.
   * @param {Boolean} options.ignoreBlank If true, treats an empty or whitespace string as not present
   * @param {Object} model
   * @param {String} attribute
   */
  function validatePresence(value, options, model, attribute) {
    var presence = options.presence,
        ignoreBlank = options.ignoreBlank;
    var v = (0, _unwrapProxy.default)(value);

    var _isPresent = ignoreBlank ? (0, _utils.isPresent)(v) : !(0, _utils.isEmpty)(v);

    (false && !((0, _utils.isPresent)(presence)) && (0, _debug.assert)("[validator:presence] [".concat(attribute, "] option 'presence' is required"), (0, _utils.isPresent)(presence)));

    if (presence === true && !_isPresent) {
      return (0, _validationError.default)('blank', value, options);
    }

    if (presence === false && _isPresent) {
      return (0, _validationError.default)('present', value, options);
    }

    return true;
  }
});