define("@huvepharma/huvepharma-aviapp-components/templates/components/charts/legends", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "ijaiJJSP",
    "block": "[[[10,\"svg\"],[15,\"height\",[29,[[30,0,[\"height\"]]]]],[15,\"width\",[29,[[30,0,[\"width\"]]]]],[14,\"x\",\"0\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"legendItems\"]]],null]],null],null,[[[1,\"    \"],[8,[39,2],null,[[\"@label\",\"@display\",\"@x\",\"@y\"],[[28,[37,3],[[30,1,[\"label\"]],\"svg\"],null],[30,1,[\"display\"]],[30,1,[\"x\"]],[30,1,[\"y\"]]]],null],[1,\"\\n\"]],[1]],null],[13]],[\"legend\"],false,[\"each\",\"-track-array\",\"charts/huve-legend\",\"module-name\"]]",
    "moduleName": "@huvepharma/huvepharma-aviapp-components/templates/components/charts/legends.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});