define("ember-concurrency/-private/tracked-state", ["exports", "@glimmer/tracking", "ember-concurrency/-private/external/task/default-state", "ember-concurrency/-private/external/task-instance/initial-state", "ember-concurrency/-private/utils"], function (_exports, _tracking, _defaultState, _initialState, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TRACKED_INITIAL_INSTANCE_STATE = _exports.TRACKED_INITIAL_TASK_STATE = void 0;

  function trackMixin(proto, obj, key) {
    var propDesc = Object.getOwnPropertyDescriptor(proto, key);

    propDesc.initializer = propDesc.initializer || function () {
      return proto[key];
    };

    delete propDesc.value;
    var desc = (0, _tracking.tracked)(obj, key, propDesc);
    obj[key] = desc;
    return obj;
  }

  function applyTracked(proto, initial) {
    return Object.keys(proto).reduce(function (acc, key) {
      return trackMixin(proto, acc, key);
    }, initial);
  }

  var TRACKED_INITIAL_TASK_STATE;
  _exports.TRACKED_INITIAL_TASK_STATE = TRACKED_INITIAL_TASK_STATE;
  var TRACKED_INITIAL_INSTANCE_STATE;
  _exports.TRACKED_INITIAL_INSTANCE_STATE = TRACKED_INITIAL_INSTANCE_STATE;

  if (_utils.USE_TRACKED) {
    _exports.TRACKED_INITIAL_TASK_STATE = TRACKED_INITIAL_TASK_STATE = applyTracked(_defaultState.DEFAULT_STATE, {});
    _exports.TRACKED_INITIAL_TASK_STATE = TRACKED_INITIAL_TASK_STATE = applyTracked({
      numRunning: 0,
      numQueued: 0,
      isRunning: false,
      isQueued: false,
      isIdle: true,
      state: 'idle'
    }, TRACKED_INITIAL_TASK_STATE);
    _exports.TRACKED_INITIAL_INSTANCE_STATE = TRACKED_INITIAL_INSTANCE_STATE = applyTracked(_initialState.INITIAL_STATE, {});
    _exports.TRACKED_INITIAL_INSTANCE_STATE = TRACKED_INITIAL_INSTANCE_STATE = applyTracked({
      state: 'waiting',
      isDropped: false,
      isRunning: false
    }, TRACKED_INITIAL_INSTANCE_STATE);
    Object.freeze(TRACKED_INITIAL_TASK_STATE);
    Object.freeze(TRACKED_INITIAL_INSTANCE_STATE);
  }
});