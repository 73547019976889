define("@huvepharma/huvepharma-aviapp-components/templates/components/programs/timeline", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "ru1bpjpc",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[33,2]],null]],null],null,[[[1,\"  \"],[10,1],[15,0,[28,[37,3],[[52,[30,1,[\"isCurrentMonth\"]],\"is-current-month \"],[52,[30,1,[\"isPast\"]],\"is-past \"],[52,[30,1,[\"isInPeriod\"]],\"is-in-period\"]],null]],[12],[1,\"\\n    \"],[1,[30,1,[\"key\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[1]],null]],[\"month\"],false,[\"each\",\"-track-array\",\"months\",\"concat\",\"if\"]]",
    "moduleName": "@huvepharma/huvepharma-aviapp-components/templates/components/programs/timeline.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});