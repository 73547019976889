define("@huvepharma/huvepharma-aviapp-components/helpers/eq", ["exports", "@ember/component/helper", "@ember/object"], function (_exports, _helper, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var eq = function eq(params) {
    // If a key is provided, compare by key
    if (params.length === 3) {
      if (!params[0] || !params[1]) {
        return false;
      }

      var key = params[2];
      return ((0, _object.get)(params[0], key) || null) === ((0, _object.get)(params[1], key) || null);
    } // Otherwise just do a standard equality check


    return params[0] === params[1];
  };

  var _default = (0, _helper.helper)(eq);

  _exports.default = _default;
});