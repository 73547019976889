define("@huvepharma/huvepharma-aviapp-components/templates/components/tree/node-header", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "skZ2eced",
    "block": "[[[10,\"li\"],[15,\"onclick\",[28,[37,0],[[30,0],[33,1],[33,2]],null]],[15,0,[28,[37,3],[[52,[33,5],\"tree-node-leaf \",\"tree-node-branch \"],[52,[33,2,[\"isSelected\"]],\"is-selected \"],[52,[33,2,[\"isExpanded\"]],\"is-expanded \"],[33,6]],null]],[12],[1,\"\\n  \"],[10,1],[14,0,\"label\"],[12],[1,\"\\n\"],[41,[48,[30,1]],[[[1,\"      \"],[18,1,[[33,2]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,1],[12],[1,[28,[35,9],[[33,2],[33,10]],null]],[13],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[41,[51,[28,[37,12],[[33,5],[28,[37,13],[[33,14],0],null]],null]],[[[1,\"    \"],[10,1],[14,0,\"count\"],[12],[1,[34,14]],[13],[1,\"\\n\"]],[]],null],[41,[28,[37,15],[[33,5],[33,2,[\"isSelected\"]]],null],[[[1,\"    \"],[10,\"svg\"],[14,0,\"check\"],[12],[10,\"use\"],[14,\"xlink:href\",\"#check\",\"http://www.w3.org/1999/xlink\"],[12],[13],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[28,[37,16],[[33,5]],null],[[[1,\"    \"],[10,\"svg\"],[14,0,\"arrow\"],[12],[10,\"use\"],[14,\"xlink:href\",\"#arrow-down\",\"http://www.w3.org/1999/xlink\"],[12],[13],[13],[1,\"\\n\"]],[]],null],[13]],[\"&default\"],false,[\"action\",\"onHandleClick\",\"data\",\"concat\",\"if\",\"isLeaf\",\"nodeClass\",\"has-block\",\"yield\",\"get\",\"labelKey\",\"unless\",\"or\",\"eq\",\"selectedCount\",\"and\",\"not\"]]",
    "moduleName": "@huvepharma/huvepharma-aviapp-components/templates/components/tree/node-header.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});