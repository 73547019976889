define("ember-cli-app-version/initializer-factory", ["exports", "ember", "@ember/string"], function (_exports, _ember, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = initializerFactory;
  var libraries = _ember.default.libraries;

  function initializerFactory(name, version) {
    var registered = false;
    return function () {
      if (!registered && name && version) {
        var appName = (0, _string.classify)(name);
        libraries.register(appName, version);
        registered = true;
      }
    };
  }
});