define("@huvepharma/huvepharma-aviapp-components/components/base-overlay", ["exports", "@ember/component", "@huvepharma/huvepharma-aviapp-components/templates/components/base-overlay"], function (_exports, _component, _baseOverlay) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    layout: _baseOverlay.default
  });

  _exports.default = _default;
});