define("@huvepharma/huvepharma-aviapp-components/templates/components/programs/program-list", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "rhgmoKz2",
    "block": "[[[10,\"section\"],[14,0,\"program-list\"],[12],[1,\"\\n  \"],[18,3,null],[1,\"\\n  \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n\"],[41,[33,2],[[[41,[33,3],[[[42,[28,[37,5],[[28,[37,5],[[33,6]],null]],null],null,[[[1,\"          \"],[1,[28,[35,7],null,[[\"program\",\"dateFormat\",\"minimal\",\"action\"],[[30,1],[33,8],[33,9],[28,[37,3],[[30,0],[33,3],[30,1]],null]]]]],[1,\"\\n\"]],[1]],null]],[]],[[[42,[28,[37,5],[[28,[37,5],[[33,6]],null]],null],null,[[[1,\"          \"],[1,[28,[35,7],null,[[\"program\",\"dateFormat\",\"minimal\"],[[30,2],[33,8],[33,9]]]]],[1,\"\\n\"]],[2]],null]],[]]],[1,\"      \\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"program\",\"program\",\"&default\"],false,[\"yield\",\"if\",\"visible\",\"action\",\"each\",\"-track-array\",\"programs\",\"programs/huve-program-item\",\"dateFormat\",\"minimal\"]]",
    "moduleName": "@huvepharma/huvepharma-aviapp-components/templates/components/programs/program-list.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});