define("@huvepharma/huvepharma-aviapp-components/templates/components/tree/node", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "mkMNNByy",
    "block": "[[[41,[51,[33,1]],[[[41,[48,[30,3]],[[[1,\"    \"],[18,3,[[28,[37,5],null,[[\"node\",\"isLeaf\",\"selectedCount\",\"onHandleClick\",\"builder\"],[[33,6],[33,7],[33,8],[33,9],[28,[37,5],null,[[\"default\"],[[50,\"tree/huve-node-header\",0,null,[[\"data\",\"labelKey\",\"isLeaf\",\"selectedCount\",\"onHandleClick\"],[[33,6],[33,11],[33,7],[33,8],[33,9]]]]]]]]]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[28,[35,12],null,[[\"data\",\"labelKey\",\"selectedCount\",\"isLeaf\",\"onHandleClick\"],[[33,6],[33,11],[33,8],[33,7],[33,9]]]]],[1,\"\\n\"]],[]]]],[]],null],[1,\"\\n\"],[41,[28,[37,13],[[33,1],[33,6,[\"isExpanded\"]]],null],[[[1,\"  \"],[10,\"ul\"],[15,0,[52,[33,1],\"ignore-root\"]],[12],[1,\"\\n\"],[42,[28,[37,15],[[28,[37,15],[[28,[37,16],[[33,6],[33,17,[\"childKey\"]]],null]],null]],null],null,[[[41,[48,[30,3]],[[[6,[39,18],null,[[\"labelKey\",\"data\",\"tree\",\"onHandleClick\"],[[33,11],[30,1],[33,17],[33,9]]],[[\"default\"],[[[[1,\"          \"],[18,3,[[30,2]]],[1,\"\\n\"]],[2]]]]]],[]],[[[1,\"        \"],[1,[28,[35,18],null,[[\"labelKey\",\"data\",\"tree\",\"onHandleClick\"],[[33,11],[30,1],[33,17],[33,9]]]]],[1,\"\\n\"]],[]]]],[1]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[\"child\",\"context\",\"&default\"],false,[\"unless\",\"ignoreRoot\",\"if\",\"has-block\",\"yield\",\"hash\",\"data\",\"isLeaf\",\"selectedCount\",\"onHandleClick\",\"component\",\"labelKey\",\"tree/huve-node-header\",\"or\",\"each\",\"-track-array\",\"get\",\"tree\",\"tree/huve-node\"]]",
    "moduleName": "@huvepharma/huvepharma-aviapp-components/templates/components/tree/node.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});