define("ember-validators/inclusion", ["exports", "@ember/debug", "@ember/utils", "ember-validators/utils/validation-error"], function (_exports, _debug, _utils, _validationError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateInclusion;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  /**
   *  @class Inclusion
   *  @module Validators
   */

  /**
   * @method validate
   * @param {Any} value
   * @param {Object} options
   * @param {Boolean} options.allowBlank If true, skips validation if the value is empty
   * @param {Array} options.in The list of values this attribute could be
   * @param {Array} options.range The range in which the attribute's value should reside in
   * @param {Object} model
   * @param {String} attribute
   */
  function validateInclusion(value, options, model, attribute) {
    var array = options.in;
    var range = options.range,
        allowBlank = options.allowBlank;
    (false && !(!(0, _utils.isEmpty)(Object.keys(options))) && (0, _debug.assert)("[validator:inclusion] [".concat(attribute, "] no options were passed in"), !(0, _utils.isEmpty)(Object.keys(options))));

    if (allowBlank && (0, _utils.isEmpty)(value)) {
      return true;
    }

    if (array && array.indexOf(value) === -1) {
      return (0, _validationError.default)('inclusion', value, options);
    }

    if (range && range.length === 2) {
      var _range = _slicedToArray(range, 2),
          min = _range[0],
          max = _range[1];

      var equalType = (0, _utils.typeOf)(value) === (0, _utils.typeOf)(min) && (0, _utils.typeOf)(value) === (0, _utils.typeOf)(max);

      if (!equalType || min > value || value > max) {
        return (0, _validationError.default)('inclusion', value, options);
      }
    }

    return true;
  }
});