define("@huvepharma/huvepharma-aviapp-components/templates/components/form-builder/field-custom-selector", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "vlBPaFG/",
    "block": "[[[6,[39,0],null,[[\"prop\",\"changeset\",\"label\",\"validate\"],[[33,1],[33,2],[33,3],[33,4]]],[[\"default\"],[[[[1,\"  \"],[18,2,[[28,[37,6],null,[[\"label\",\"input\",\"errors\"],[[30,1,[\"label\"]],[50,\"form/huve-custom-selector\",0,null,[[\"value\",\"class\"],[[28,[37,8],[[33,2],[33,1]],null],[52,[30,1,[\"hasErrors\"]],\"error\"]]]],[30,1,[\"errors\"]]]]]]],[1,\"\\n\"]],[1]]]]]],[\"field\",\"&default\"],false,[\"form-builder/huve-field-wrapper\",\"prop\",\"changeset\",\"label\",\"validate\",\"yield\",\"hash\",\"component\",\"get\",\"if\"]]",
    "moduleName": "@huvepharma/huvepharma-aviapp-components/templates/components/form-builder/field-custom-selector.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});