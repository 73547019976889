define("@huvepharma/huvepharma-aviapp-components/helpers/subtract", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.subtract = subtract;
  _exports.default = void 0;

  /**
   * Subtracts two numeric params, outputs a single value
   */
  function subtract(params) {
    if (params[0] && params[1]) {
      var firstNumber = parseInt(params[0], 10);
      var secondNumber = parseInt(params[1], 10);
      return firstNumber - secondNumber;
    }

    return params[0];
  }

  var _default = (0, _helper.helper)(subtract);

  _exports.default = _default;
});