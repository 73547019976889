define("@huvepharma/huvepharma-aviapp-components/templates/components/form/field-number-step", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "uJVaizkt",
    "block": "[[[41,[30,1],[[[1,\"  \"],[10,1],[14,0,\"minus-icon-button disabled\"],[15,\"data-test-id\",[29,[[30,2],\"-min\"]]],[12],[13],[1,\"\\n  \"],[8,[39,1],[[16,\"testId\",[30,2]],[16,\"disabled\",[30,1]],[16,1,[30,2]]],[[\"@type\",\"@value\"],[\"number\",[30,0,[\"value\"]]]],null],[1,\"\\n  \"],[10,1],[14,0,\"plus-icon-button disabled\"],[15,\"data-test-id\",[29,[[30,2],\"-add\"]]],[12],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[11,1],[24,\"role\",\"button\"],[16,0,[29,[\"minus-icon-button \",[52,[30,0,[\"isMinValue\"]],\"disabled\"],\" \",[52,[30,3],\"error\"]]]],[16,\"data-test-id\",[29,[[30,2],\"-min\"]]],[4,[38,2],[\"click\",[30,0,[\"decrease\"]]],null],[12],[1,\"\\n  \"],[13],[1,\"\\n  \"],[8,[39,3],[[4,[38,2],[\"focusout\",[30,0,[\"onFocusOut\"]]],null]],[[\"@class\",\"@value\",\"@disabled\",\"@data-test-id\",\"@options\",\"@update\"],[[52,[30,3],\"error\"],[30,0,[\"value\"]],[30,1],[30,2],[30,0,[\"maskOptions\"]],[30,0,[\"update\"]]]],null],[1,\"\\n  \"],[11,1],[24,\"role\",\"button\"],[16,0,[29,[\"plus-icon-button \",[52,[30,0,[\"isMaxValue\"]],\"disabled\"],\" \",[52,[30,3],\"error\"]]]],[16,\"data-test-id\",[29,[[30,2],\"-add\"]]],[4,[38,2],[\"click\",[30,0,[\"increase\"]]],null],[12],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[\"@disabled\",\"@testId\",\"@error\"],false,[\"if\",\"input\",\"on\",\"one-way-number-mask\"]]",
    "moduleName": "@huvepharma/huvepharma-aviapp-components/templates/components/form/field-number-step.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});