define("@huvepharma/huvepharma-aviapp-components/templates/components/header/header-icon-button", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "no/F7/Tw",
    "block": "[[[10,\"svg\"],[12],[10,\"use\"],[15,\"xlink:href\",[29,[\"#\",[36,0]]],\"http://www.w3.org/1999/xlink\"],[12],[13],[13],[1,\"\\n\"],[10,0],[14,0,\"spinner-icon\"],[12],[13],[1,\"\\n\"],[41,[33,2],[[[1,\"  \"],[10,2],[12],[1,[34,2]],[13],[1,\"\\n\"]],[]],null]],[],false,[\"icon\",\"if\",\"label\"]]",
    "moduleName": "@huvepharma/huvepharma-aviapp-components/templates/components/header/header-icon-button.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});