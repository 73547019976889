define("@huvepharma/huvepharma-aviapp-components/components/form/base-number-input", ["exports", "@ember/component", "@ember/object", "@huvepharma/huvepharma-aviapp-components/utils/input"], function (_exports, _component, _object, _input) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MINUS_KEYCODE = 45;
  var ATTRIBUTE_BINDINGS = ['type', 'value', 'enforcementType', 'id', 'testId:data-test-id', 'min', 'max', 'maxlength', 'step', 'disabled', 'pattern', 'inputmode', 'placeholder', 'autocomplete'];

  function formatDecimalPlaces(value, multiplier) {
    return Math.round(value * multiplier) / multiplier;
  }

  function validateDecimalValue(value) {
    var regexValidator = /^\d+(\.)?(\d{1,3})?$/;
    return regexValidator.test(value);
  }

  var _default = _component.default.extend({
    tagName: 'input',
    attributeBindings: ATTRIBUTE_BINDINGS,
    type: 'text',
    pattern: '[0-9]*',
    inputmode: 'numeric',
    enforcementType: 'numeric',
    positive: false,
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var unparsedValue = (0, _object.get)(this, 'unparsedValue');

      if (unparsedValue || unparsedValue === 0) {
        var formattedValue = this.executeFormatting(unparsedValue);
        this.set('value', formattedValue);
      } else if (unparsedValue === '') {
        this.set('value', '');
      }
    },

    /**
     * Handle oninput
     * @param {Event} event
     */
    input: function input(event) {
      var _this$update;

      (_this$update = this.update) === null || _this$update === void 0 ? void 0 : _this$update.call(this, event);
    },

    /**
     * Handle onkeypress
     * @param {Event} event
     */
    keyPress: function keyPress(event) {
      event.target.value = event.target.value.toString().replace(',', '.');
      return this.sanitizeInput(event);
    },

    /**
     * On change
     */
    change: function change(event) {
      var _this$update2;

      var value = this.executeFormatting(event.target.value);
      this.set('value', value);
      event.target.value = value;
      (_this$update2 = this.update) === null || _this$update2 === void 0 ? void 0 : _this$update2.call(this, event);
    },

    /**
     * On focus out
     */
    focusOut: function focusOut(event) {
      if (event.target.value === '' && !(0, _object.get)(this, 'allowEmpty')) {
        event.target.value = 0;
      }

      if (this.attrs.focusOut) {
        this.attrs.focusOut(event);
      }
    },

    /**
     * On focus in
     */
    focusIn: function focusIn(event) {
      if (Number(event.target.value) === 0 && !(0, _object.get)(this, 'allowEmpty')) {
        event.target.value = '';
      }

      if (this.attrs.focusIn) {
        this.attrs.focusIn(event);
      }
    },

    /**
     * Prevent the usage of the MINUS symbol
     * @param {Event} event
     */
    sanitizeInput: function sanitizeInput(event) {
      var keyCode = event.keyCode || event.which; // If positive is enabled, do not allow any minus keycodes

      if (this.get('positive') && keyCode === MINUS_KEYCODE) {
        event.preventDefault();
        return;
      } // Only allow the first minus keycode


      if (!this.get('positive') && event.target.value.length > 0 && keyCode === MINUS_KEYCODE) {
        event.preventDefault();
        return;
      }

      if (this.get('enforcementType') === 'numeric' && (0, _input.enforceNumeric)(event) || this.get('enforcementType') === 'decimal' && (0, _input.enforceDecimal)(event)) {
        event.preventDefault();
        return;
      } //Do not enforce min or max when min or max is a decimal place number
      //this has unexpected side-effects


      if (Number.isInteger(this.min) && (0, _input.enforceMinimum)(event, this.min, event.target.value)) {
        var _this$update3;

        this.set('value', this.min);
        (_this$update3 = this.update) === null || _this$update3 === void 0 ? void 0 : _this$update3.call(this, {
          target: {
            value: this.value
          }
        });
      }

      if (Number.isInteger(this.max) && (0, _input.enforceMaximum)(event, this.max, event.target.value)) {
        var _this$update4;

        this.set('value', this.max);
        (_this$update4 = this.update) === null || _this$update4 === void 0 ? void 0 : _this$update4.call(this, {
          target: {
            value: this.value
          }
        });
      }

      if ((0, _input.checkMaximumCharacters)(event, (0, _object.get)(this, 'maxCharacters'), (0, _object.get)(this, 'enforcementType'), event.target.value)) {
        return; // eslint-disable-line
      }
    },
    executeFormatting: function executeFormatting(value) {
      if (value && this.decimalPlaces) {
        var multiplier = Math.pow(10, this.decimalPlaces);
        return formatDecimalPlaces(value, multiplier);
      }

      var result = value;

      if (!validateDecimalValue(value)) {
        if (typeof value === 'number') {
          return formatDecimalPlaces(value, 1000);
        }
      }

      if (value === '' && this.allowEmpty) {
        return '';
      }

      var min = Number((0, _object.get)(this, 'min'));

      if (min != null && result < min) {
        result = min;
      } // If there is a maximum for this field - apply it


      var max = Number(this.get('max'));

      if (max && value > max) {
        result = max;
      }

      return result;
    }
  });

  _exports.default = _default;
});