define("@huvepharma/huvepharma-aviapp-components/templates/components/photo/xamarin-camera-gallery", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "byOtQ6Wl",
    "block": "[[[41,[33,1],[[[6,[39,2],null,[[\"to\"],[\"modal-wormhole\"]],[[\"default\"],[[[[1,\"    \"],[10,0],[14,0,\"mod-modal is-visible\"],[12],[1,\"\\n        \"],[10,1],[14,0,\"mod-modal-overlay\"],[12],[1,\"\\n            \"],[10,0],[14,0,\"mod-modal-content-wrapper camera-gallery-selector-position\"],[12],[1,\"\\n                \"],[10,0],[14,0,\"camera-gallery-options\"],[12],[1,\"\\n                    \"],[10,\"ul\"],[14,0,\"camera-gallery-selection\"],[12],[1,\"\\n                        \"],[10,\"li\"],[15,\"onclick\",[28,[37,3],[[30,0],[33,4]],null]],[12],[1,\"\\n                            \"],[1,[28,[35,5],[\"photo_take_photo\"],null]],[1,\"\\n                            \"],[10,\"svg\"],[14,0,\"flock-card-info-icon\"],[12],[1,\"\\n                                \"],[10,\"use\"],[14,\"xlink:href\",\"#camera\",\"http://www.w3.org/1999/xlink\"],[12],[13],[1,\"\\n                            \"],[13],[1,\"\\n                        \"],[13],[1,\"\\n                        \"],[10,\"li\"],[15,\"onclick\",[28,[37,3],[[30,0],[33,6]],null]],[12],[1,\"\\n                            \"],[1,[28,[35,5],[\"photo_upload_photo\"],null]],[1,\"\\n                            \"],[10,\"svg\"],[14,0,\"flock-card-info-icon\"],[12],[1,\"\\n                                \"],[10,\"use\"],[14,\"xlink:href\",\"#gallery\",\"http://www.w3.org/1999/xlink\"],[12],[13],[1,\"\\n                            \"],[13],[1,\"\\n                        \"],[13],[1,\"\\n                    \"],[13],[1,\"\\n                    \"],[10,0],[14,0,\"cancel-selector\"],[15,\"onclick\",[28,[37,3],[[30,0],[33,7]],null]],[12],[1,\"\\n                        \"],[1,[28,[35,5],[\"app_action_cancel\"],null]],[1,\"\\n                    \"],[13],[1,\"\\n                \"],[13],[1,\"\\n            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]]],[]],null]],[],false,[\"if\",\"visible\",\"ember-wormhole\",\"action\",\"openCamera\",\"t\",\"openGallery\",\"onClose\"]]",
    "moduleName": "@huvepharma/huvepharma-aviapp-components/templates/components/photo/xamarin-camera-gallery.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});