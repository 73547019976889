define("@huvepharma/huvepharma-aviapp-components/templates/components/overlay/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "9W4vT8jI",
    "block": "[[[6,[39,0],null,[[\"to\"],[\"overlay-wormhole\"]],[[\"default\"],[[[[1,\"  \"],[10,0],[12],[1,\"\\n\"],[41,[33,2],[[[1,\"      \"],[10,0],[15,0,[29,[\"overlay \",[52,[33,3],\"is-transparent\"],\" \",[52,[33,4],\"is-topmost\"],\" \",[36,5]]]],[12],[1,\"\\n        \"],[10,0],[15,0,[29,[\"overlay-content-container \",[52,[28,[37,6],[[33,7],\"right\"],null],\"is-reverse\"]]]],[12],[1,\"\\n          \"],[18,1,null],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]]]]]],[\"&default\"],false,[\"ember-wormhole\",\"if\",\"isVisible\",\"isTransparent\",\"isTopmost\",\"classes\",\"eq\",\"flexDirection\",\"yield\"]]",
    "moduleName": "@huvepharma/huvepharma-aviapp-components/templates/components/overlay/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});