define("@huvepharma/huvepharma-aviapp-components/utils/input", ["exports", "lodash-es/isNaN"], function (_exports, _isNaN) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.enforceMinimum = enforceMinimum;
  _exports.enforceMaximum = enforceMaximum;
  _exports.checkMaximumCharacters = checkMaximumCharacters;
  _exports.enforceNumeric = enforceNumeric;
  _exports.enforceDecimal = enforceDecimal;
  var MINUS_KEYCODE = 45;
  var BACKSPACE_KEYCODE = 8;
  var ENTER_KEYCODE = 13;
  var TAB_KEYCODE = 9;
  var LEFT_KEYCODE = 37;
  var RIGHT_KEYCODE = 39;
  var POINT_KEYCODE = 44;
  var COMMA_KEYCODE = 46;
  var DECIMAL_POINT_KEYCODE = 110;
  var allowed = [BACKSPACE_KEYCODE, TAB_KEYCODE, ENTER_KEYCODE, MINUS_KEYCODE, LEFT_KEYCODE, RIGHT_KEYCODE];
  var allowedForDecimal = [].concat(allowed, [POINT_KEYCODE, COMMA_KEYCODE, DECIMAL_POINT_KEYCODE]);
  /**
   * Enforces that the key pressed is above the minimum,
   * if not this function preventsDefault on the event.
   * @param {Event} event The event
   * @param {Number|String} minValue the minimum value
   * @param {String} currentValue The current value
   * @returns {Boolean} If the event was prevented
   */

  function enforceMinimum(event, minValue) {
    var currentValue = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
    var keyCode = event.keyCode || event.which; // Enforce minimum

    if (!(0, _isNaN.default)(minValue) || String(minValue) === '0' || String(minValue) === '-') {
      var newValue = Number(currentValue + String.fromCharCode(keyCode));

      if (!(0, _isNaN.default)(newValue) && newValue < Number(minValue)) {
        event.preventDefault();
        return true;
      }
    }

    return false;
  }
  /**
   * Enforces that the key pressed is less than the maximum,
   * if not this function preventsDefault on the event.
   * @param {Event} event The event
   * @param {Number|String} maxValue the maximum value
   * @param {String} currentValue The current value
   * @returns {Boolean} If the event was prevented
   */


  function enforceMaximum(event, maxValue) {
    var currentValue = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
    var keyCode = event.keyCode || event.which;
    var isInputTextFullyHighlighted = event.target.selectionStart === 0 && event.target.selectionEnd === event.target.value.length;

    if (isInputTextFullyHighlighted) {
      return false;
    } // Enforce maximum


    if (maxValue && !(0, _isNaN.default)(maxValue)) {
      var newValue = Number(currentValue + String.fromCharCode(keyCode));

      if (!(0, _isNaN.default)(newValue) && newValue > Number(maxValue)) {
        event.preventDefault();
        return true;
      }
    }

    return false;
  }
  /**
   * Checks if the value length doens't exceed the max characters
   * if not this function preventsDefault on the event.
   * @param {Event} event The event
   * @param {Number|String} maxCharacters the maximum characters value
   * @param {String} currentValue The current value
   * @returns {Boolean} If the event was prevented
   */


  function checkMaximumCharacters(event, maxCharacters, enforcementType) {
    var currentValue = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';

    if (!(0, _isNaN.default)(maxCharacters) && currentValue.length > maxCharacters - 1 && enforcementType === 'numeric') {
      event.preventDefault();
      return true;
    }

    return false;
  }
  /**
   * Enforces that the key pressed is 0 to 9, or allowed keyboard events,
   * if not this function preventsDefault on the event.
   * @param {Event} event
   * @param {Array<Number>} allowedKeyCodes
   * @returns {Boolean} If the event was prevented
   */


  function genericEnforcement(event, allowedKeyCodes) {
    var keyCode = event.keyCode || event.which; // Enforce 0-9 and accepted keycodes only

    if (event.which === "'".charCodeAt() || !allowedKeyCodes.includes(keyCode) && (keyCode < '0'.charCodeAt() || keyCode > '9'.charCodeAt())) {
      event.preventDefault();
      return true;
    }

    return false;
  }
  /**
   * Enforces that the key pressed is 0 to 9, or allowed keyboard events,
   * if not this function preventsDefault on the event.
   * @param {Event} event
   * @returns {Boolean} If the event was prevented
   */


  function enforceNumeric(event) {
    return genericEnforcement(event, allowed);
  }
  /**
   * Enforces that the key pressed is 0 to 9 or a decimal point, or allowed keyboard events,
   * if not this function preventsDefault on the event.
   * @param {Event} event
   * @returns {Boolean} If the event was prevented
   */


  function enforceDecimal(event) {
    return genericEnforcement(event, allowedForDecimal);
  }
});