define("@huvepharma/huvepharma-aviapp-components/helpers/region-code", ["exports", "@ember/component/helper", "@ember/service", "@ember/object", "@huvepharma/huvepharma-aviapp-components/constants"], function (_exports, _helper, _service, _object, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.translate = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }

  var flattenRegions = function flattenRegions(regions) {
    return _toConsumableArray(regions.map(function (map) {
      return map.items;
    })).reduce(function (acc, val) {
      return acc.concat(val);
    }, []);
  };

  var translate = function translate(regions, regionKey) {
    if (!regions) {
      return regionKey;
    }

    var translatedRegion = flattenRegions(regions).find(function (region) {
      return region.code === regionKey;
    });

    if (translatedRegion) {
      return translatedRegion.label;
    }

    return regionKey;
  };

  _exports.translate = translate;

  var _default = _helper.default.extend({
    regions: (0, _service.inject)(),
    intl: (0, _service.inject)(),
    compute: function compute(params) {
      if (params[1] === _constants.UNKNOWN) {
        return (0, _object.get)(this, 'intl').t('label_region_unknown');
      }

      var regionsForCountry = (0, _object.get)(this, 'regions').getRegionsForCountry(params[0]);
      return translate(regionsForCountry, params[1]);
    }
  });

  _exports.default = _default;
});