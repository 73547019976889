define("@huvepharma/huvepharma-aviapp-components/templates/components/form/country-select", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "lwa4otEF",
    "block": "[[[1,[28,[35,0],null,[[\"testId\",\"content\",\"action\",\"itemValue\",\"itemLabel\",\"error\",\"class\",\"entity\",\"selectedValue\",\"prompt\",\"customPrompt\"],[[33,1],[33,2],[28,[37,3],[[30,0],[28,[37,4],[[33,5]],null]],null],\"code\",\"country\",[33,6],[33,7],\"Country\",[33,8],true,[28,[37,9],[\"farms_label_selectcountry\"],null]]]]],[1,\"\\n\"]],[],false,[\"form/huve-select-box\",\"testId\",\"options\",\"action\",\"mut\",\"countryCode\",\"error\",\"class\",\"selectedValue\",\"t\"]]",
    "moduleName": "@huvepharma/huvepharma-aviapp-components/templates/components/form/country-select.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});