define("@huvepharma/huvepharma-aviapp-components/helpers/includes", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.includes = includes;
  _exports.default = void 0;

  /**
   * Checks if a given array includes the given variable
   * @param {Array} params
   * @returns {Boolean}
   */
  function includes(params) {
    var array = params[0];

    if (!Array.isArray(array)) {
      if (array.toArray && typeof array.toArray === 'function') {
        array = array.toArray();
      } else {
        array = [];
      }
    }

    var variable = params[1];
    return array.includes(variable);
  }

  var _default = (0, _helper.helper)(includes);

  _exports.default = _default;
});