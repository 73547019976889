define("@huvepharma/huvepharma-aviapp-components/components/tables/gfr-table-row", ["exports", "@ember/component", "@huvepharma/huvepharma-aviapp-components/templates/components/tables/gfr-table-row"], function (_exports, _component, _gfrTableRow) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    layout: _gfrTableRow.default,
    rootTestId: 'gfr-table-row',
    actions: {
      update: function update(id, index, event) {
        var _this$update;

        (_this$update = this.update) === null || _this$update === void 0 ? void 0 : _this$update.call(this, id, index, event.target.value);
      }
    }
  });

  _exports.default = _default;
});