define("@huvepharma/huvepharma-aviapp-components/templates/components/header/header-text-button", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "o7+VS0r1",
    "block": "[[[41,[33,1],[[[1,\"  \"],[10,0],[14,0,\"mr-1\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"spinner-icon primary\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,[34,2]]],[],false,[\"if\",\"loading\",\"label\"]]",
    "moduleName": "@huvepharma/huvepharma-aviapp-components/templates/components/header/header-text-button.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});