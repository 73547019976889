define("@huvepharma/huvepharma-aviapp-components/templates/components/character-counter", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "jVgqUp1l",
    "block": "[[[10,0],[15,0,[29,[\"counter \",[52,[28,[37,1],[[33,2]],null],\"transparent\"],\" \",[52,[33,3],\"threshold\"]]]],[12],[1,\"\\n  \"],[1,[28,[35,4],[\"label_characters_left\"],null]],[1,\": \"],[10,1],[12],[1,[34,5]],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"if\",\"not\",\"characterCountVisible\",\"threshold\",\"t\",\"charactersLeft\"]]",
    "moduleName": "@huvepharma/huvepharma-aviapp-components/templates/components/character-counter.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});