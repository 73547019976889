define("@huvepharma/huvepharma-aviapp-components/components/form-builder/field-custom-selector", ["exports", "@huvepharma/huvepharma-aviapp-components/components/form-builder/field-base", "@huvepharma/huvepharma-aviapp-components/templates/components/form-builder/field-custom-selector"], function (_exports, _fieldBase, _fieldCustomSelector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fieldBase.default.extend({
    layout: _fieldCustomSelector.default
  });

  _exports.default = _default;
});