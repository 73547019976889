define("ember-cli-flash/utils/flash-message-options", ["exports", "@ember/polyfills"], function (_exports, _polyfills) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  /* eslint-disable ember/new-module-imports */
  function _default(configOverrides) {
    var addonDefaults = {
      timeout: 3000,
      extendedTimeout: 0,
      priority: 100,
      sticky: false,
      showProgress: false,
      type: 'info',
      types: ['success', 'info', 'warning', 'danger', 'alert', 'secondary'],
      injectionFactories: ['route', 'controller', 'view', 'component'],
      preventDuplicates: false
    };
    return (0, _polyfills.assign)(addonDefaults, configOverrides);
  }
});