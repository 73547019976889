define("ember-infinity/lib/infinity-promise-array", ["exports", "@ember/object/promise-proxy-mixin", "@ember/array/proxy"], function (_exports, _promiseProxyMixin, _proxy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _proxy.default.extend(_promiseProxyMixin.default);

  _exports.default = _default;
});