define("@huvepharma/huvepharma-aviapp-components/utils/paged-array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(content, page, itemsPerPage) {
    var start = (page - 1) * itemsPerPage;
    var end = start + itemsPerPage;
    return content.slice(start, end);
  }
});