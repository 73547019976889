define("@huvepharma/huvepharma-aviapp-components/templates/components/header/back-link", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "semmpxtU",
    "block": "[[[10,\"svg\"],[14,0,\"svgSmaller\"],[12],[1,\"\\n  \"],[10,\"use\"],[14,\"xlink:href\",\"#arrow-left\",\"http://www.w3.org/1999/xlink\"],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,1],[14,0,\"back-link-text\"],[12],[1,[34,0]],[13]],[],false,[\"text\"]]",
    "moduleName": "@huvepharma/huvepharma-aviapp-components/templates/components/header/back-link.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});