define("@huvepharma/huvepharma-aviapp-components/components/modal/modal-base", ["exports", "@ember/object/computed", "@ember/component", "@huvepharma/huvepharma-aviapp-components/templates/components/modal/modal-base"], function (_exports, _computed, _component, _modalBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    testIdValue: (0, _computed.alias)('testId'),
    layout: _modalBase.default
  });

  _exports.default = _default;
});