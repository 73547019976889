define("@huvepharma/huvepharma-aviapp-components/helpers/case-insensitive-eq", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var caseInsensitiveEq = function caseInsensitiveEq(params) {
    // Otherwise just do a standard equality check
    if (typeof params[0] === 'string' && typeof params[1] === 'string') {
      return params[0].toLowerCase() === params[1].toLowerCase();
    }

    return params[0] === params[1];
  };

  var _default = (0, _helper.helper)(caseInsensitiveEq);

  _exports.default = _default;
});