define("@huvepharma/huvepharma-aviapp-components/templates/components/tag-list", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "0kFaGWVX",
    "block": "[[[10,0],[15,0,[29,[\"tag-list \",[52,[30,0,[\"isEmpty\"]],\"empty\"]]]],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,1]],null]],null],null,[[[1,\"    \"],[10,1],[12],[1,\"\\n      \"],[11,\"button\"],[24,0,\"tag\"],[16,\"data-test-id\",[30,2,[\"id\"]]],[24,4,\"button\"],[4,[38,3],[\"click\",[28,[37,4],[[30,3],[30,2]],null]],null],[12],[1,\"\\n\"],[41,[48,[30,4]],[[[1,\"          \"],[18,4,[[30,2]]],[1,\"\\n\"]],[]],[[[1,\"          \"],[1,[28,[35,7],[[30,2,[\"label\"]]],null]],[1,\"\\n\"]],[]]],[1,\"        \"],[10,\"svg\"],[12],[1,\"\\n          \"],[10,\"use\"],[14,\"xlink:href\",\"#cancel\",\"http://www.w3.org/1999/xlink\"],[12],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[2]],null],[13]],[\"@tags\",\"tag\",\"@onClick\",\"&default\"],false,[\"if\",\"each\",\"-track-array\",\"on\",\"fn\",\"has-block\",\"yield\",\"t\"]]",
    "moduleName": "@huvepharma/huvepharma-aviapp-components/templates/components/tag-list.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});