define("@huvepharma/huvepharma-aviapp-components/components/buttons/add-button", ["exports", "@ember/component", "@huvepharma/huvepharma-aviapp-components/templates/components/buttons/add-button"], function (_exports, _component, _addButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    layout: _addButton.default,
    link: '#',

    /**
     * Click action.
     * Fire the given action, with a param if present.
     */
    click: function click(e) {
      var link = this.get('link');

      if (!link || link === '#') {
        e.preventDefault();
      }

      var param = this.get('param');

      if (this.get('action') && param !== undefined) {
        var _this$action;

        (_this$action = this.action) === null || _this$action === void 0 ? void 0 : _this$action.call(this, param);
      } else {
        var _this$action2;

        (_this$action2 = this.action) === null || _this$action2 === void 0 ? void 0 : _this$action2.call(this);
      }
    }
  });

  _exports.default = _default;
});