define("@huvepharma/huvepharma-aviapp-components/constants/gfr-raw-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DEFAULT_FOOTER = _exports.FIXED_HEADER = _exports.ONLY_HEADER_TITLE = _exports.DEFAULT_HEADER = _exports.EXAMPLE_FIELDS_2 = _exports.EXAMPLE_FIELDS = _exports.EXAMPLE_FIELD_STRUCTURE = void 0;
  var EXAMPLE_FIELD_STRUCTURE = [{
    id: 'mortalityDeaths',
    input: true,
    min: 0,
    positive: true,
    max: 100,
    step: 1,
    type: 'number',
    showError: true
  }, {
    id: 'mortalityCulled',
    input: true,
    min: 0,
    positive: true,
    max: 100,
    step: 1,
    type: 'number',
    showError: true
  }, {
    id: 'mortalityThinning',
    input: true,
    min: 0,
    positive: true,
    max: 100,
    step: 1,
    type: 'number',
    showError: true
  }, {
    id: 'mortalityTotal',
    input: false,
    showError: true
  }, {
    id: 'dailyFeedIntake',
    input: true,
    min: 0,
    max: 100,
    positive: true,
    step: 1,
    unit: true,
    type: 'number'
  }, {
    id: 'averageFeedIntake',
    input: false
  }, {
    id: 'dailyWaterIntake',
    input: true,
    min: 0,
    positive: true,
    max: 100,
    step: 1,
    unit: true,
    type: 'number'
  }, {
    id: 'averageWaterIntake',
    input: false
  }, {
    id: 'bodyweightPerBird',
    input: true,
    min: 0,
    max: 100,
    step: 1,
    unit: true,
    maxChar: 4
  }, {
    id: 'bodyweight',
    input: false
  }];
  _exports.EXAMPLE_FIELD_STRUCTURE = EXAMPLE_FIELD_STRUCTURE;
  var EXAMPLE_FIELDS = [{
    id: 'mortalityDeaths',
    value: 2
  }, {
    id: 'mortalityCulled',
    value: 1
  }, {
    id: 'mortalityThinning',
    value: 1
  }, {
    id: 'mortalityTotal',
    value: '0'
  }, {
    id: 'dailyFeedIntake',
    value: 20
  }, {
    id: 'averageFeedIntake',
    value: 20
  }, {
    id: 'dailyWaterIntake',
    value: 20
  }, {
    id: 'averageWaterIntake',
    value: 20
  }, {
    id: 'bodyweightPerBird',
    value: 150
  }, {
    id: 'bodyweight',
    value: '140'
  }];
  _exports.EXAMPLE_FIELDS = EXAMPLE_FIELDS;
  var EXAMPLE_FIELDS_2 = [{
    id: 'mortalityDeaths',
    value: 2
  }, {
    id: 'mortalityCulled',
    value: 5
  }, {
    id: 'mortalityThinning',
    value: 2
  }, {
    id: 'mortalityTotal',
    value: '1.2%'
  }, {
    id: 'dailyFeedIntake',
    value: 50
  }, {
    id: 'averageFeedIntake',
    value: 40
  }, {
    id: 'dailyWaterIntake',
    value: 90
  }, {
    id: 'averageWaterIntake',
    value: 60
  }, {
    id: 'bodyweightPerBird',
    value: 160
  }, {
    id: 'bodyweight',
    value: '80'
  }];
  _exports.EXAMPLE_FIELDS_2 = EXAMPLE_FIELDS_2;
  var DEFAULT_HEADER = [{
    title: 'Mortality',
    children: [{
      title: 'deaths',
      subtitle: '(# birds)'
    }, {
      title: 'culled',
      subtitle: '(# birds)'
    }, {
      title: 'thinning',
      subtitle: '(# birds)'
    }]
  }, {
    title: 'Total',
    subtitle: '(%)'
  }, {
    title: 'Feed intake',
    children: [{
      title: 'daily',
      subtitle: '(kg/flock)'
    }, {
      title: 'average',
      subtitle: '(g/bird)'
    }]
  }, {
    title: 'Water intake',
    children: [{
      title: 'daily',
      subtitle: '(L/flock)'
    }, {
      title: 'average',
      subtitle: '(ml/bird)'
    }]
  }, {
    title: 'Body weight',
    children: [{
      title: 'weight/bird',
      subtitle: '(g)'
    }, {
      title: 'guideline',
      subtitle: '(g)'
    }]
  }];
  _exports.DEFAULT_HEADER = DEFAULT_HEADER;
  var ONLY_HEADER_TITLE = [{
    title: 'Days'
  }, {
    title: 'Days'
  }, {
    title: 'Days'
  }, {
    title: 'Days'
  }, {
    title: 'Days'
  }, {
    title: 'Days'
  }];
  _exports.ONLY_HEADER_TITLE = ONLY_HEADER_TITLE;
  var FIXED_HEADER = [{
    title: 'Days',
    subtitle: ' '
  }];
  _exports.FIXED_HEADER = FIXED_HEADER;
  var DEFAULT_FOOTER = [{
    id: 'mortalityDeaths',
    input: false,
    value: '4253'
  }, {
    id: 'mortalityCulled',
    input: false,
    value: '5'
  }, {
    id: 'mortalityThinning',
    input: false,
    value: '5100'
  }, {
    id: 'mortalityTotal',
    input: false,
    value: '3,80%'
  }, {
    id: 'dailyFeedIntake',
    input: false,
    value: ''
  }, {
    id: 'averageFeedIntake',
    input: false,
    value: ''
  }, {
    id: 'dailyWaterIntake',
    input: false,
    value: ''
  }, {
    id: 'averageWaterIntake',
    input: false,
    value: ''
  }, {
    id: 'bodyweightPerBird',
    input: false,
    value: ''
  }, {
    id: 'bodyweight',
    input: false,
    value: ''
  }];
  _exports.DEFAULT_FOOTER = DEFAULT_FOOTER;
});