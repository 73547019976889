define("@huvepharma/huvepharma-aviapp-components/components/header/detail-header", ["exports", "@ember/object", "@ember/component", "@huvepharma/huvepharma-aviapp-components/templates/components/header/detail-header"], function (_exports, _object, _component, _detailHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    layout: _detailHeader.default,
    tagName: 'header',
    classNames: ['detail-header'],
    attributeBindings: ['dataTestId:data-test-id'],
    dataTestId: (0, _object.computed)('testId', function () {
      return this.get('testId') || 'detail-header';
    })
  });

  _exports.default = _default;
});