define("@huvepharma/huvepharma-aviapp-components/utils/filter", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = filterList;
  _exports.SEARCH_FILTER = void 0;
  var SEARCH_FILTER = 'SEARCH_FILTER';
  /**
   * Manually filter over a list given a provided list of filters
   * @param  {Array} list
   * @param  {Array} filters
   * @return {Array} result of filter
   */

  _exports.SEARCH_FILTER = SEARCH_FILTER;

  function filterList(list, filters) {
    return list.filter(function (item) {
      var categoryMatches = filters.filter(function (category) {
        // Callback function with custom filtering
        if ((0, _object.get)(category, 'callback')) {
          return (0, _object.get)(category, 'callback')(item);
        } // If category filter is an array, it includes multiple callbacks


        if (Array.isArray(category)) {
          return category.some(function (filter) {
            var _filter$callback;

            return (_filter$callback = filter.callback) === null || _filter$callback === void 0 ? void 0 : _filter$callback.call(filter, item);
          });
        } // Filter on array of selected values for a given cateogry


        var valueMatches = (0, _object.get)(category, 'values').filter(function (value) {
          var key = (0, _object.get)(category, 'id');

          if (typeof filter === 'string' && typeof (0, _object.get)(item, key) === 'string') {
            return (0, _object.get)(item, key).toLowerCase() === value.toLowerCase();
          }

          return (0, _object.get)(item, key) === value;
        });
        return (0, _object.get)(valueMatches, 'length') > 0;
      });
      return (0, _object.get)(categoryMatches, 'length') === (0, _object.get)(filters, 'length');
    });
  }
});