define("@huvepharma/huvepharma-aviapp-components/utils/validateSpecialCharacters", ["exports", "xregexp"], function (_exports, _xregexp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateSpecialCharacters;

  /**
   * Validate if value has special characters like punctuation marks
   * @param {String} value Text from input field
   * @param {String} description Translated string of error message
   * If regex test is true
   * @returns {Boolean} True
   * else
   * @returns {String} Translated string of error message
   */
  function validateSpecialCharacters(value, description) {
    /**
     * Uses lib: XregExp || http://xregexp.com/
     * Currently allows letters (containing western alphabet,
     * cyrillic, chinese, ...) and Thai alphabet
     */
    if (value === undefined) {
      return description;
    }

    return (0, _xregexp.default)('^[\\p{L}\\p{Thai}}0-9-_ ]+$').test(value) || description;
  }
});