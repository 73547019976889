define("@huvepharma/huvepharma-aviapp-components/services/international-unit-system", ["exports", "@ember/object", "@ember/service"], function (_exports, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _service.default.extend({
    intl: (0, _service.inject)(),

    /**
     * Add the SI unit to a given number: 1000 => 1k, 2000000 => 2M
     * https://stackoverflow.com/questions/9461621/format-a-number-as-2-5k-if-a-thousand-or-more-otherwise-900#answer-40724354
     * @param {Number} number
     */
    getNumberAsSIString: function getNumberAsSIString(number) {
      var i18nService = (0, _object.get)(this, 'intl');
      var SI_SYMBOLS = ['', i18nService.t('label_SI_thousand'), i18nService.t('label_SI_million'), i18nService.t('label_SI_billion'), i18nService.t('label_SI_trillion'), i18nService.t('label_SI_quadrillion'), i18nService.t('label_SI_quintillion'), i18nService.t('label_SI_sextillion'), i18nService.t('label_SI_septillion')];
      var tier = Math.log10(number) / 3 | 0; // eslint-disable-line no-bitwise

      if (tier === 0) {
        return number;
      } // get suffix and determine scale


      var suffix = SI_SYMBOLS[tier];
      var scale = Math.pow(10, tier * 3); // scale the number

      var scaled = number / scale; // add suffix

      return scaled + suffix;
    }
  });

  _exports.default = _default;
});