define("@huvepharma/huvepharma-aviapp-components/components/header/header-text-button", ["exports", "@ember/component", "@huvepharma/huvepharma-aviapp-components/templates/components/header/header-text-button"], function (_exports, _component, _headerTextButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    layout: _headerTextButton.default,
    tagName: 'button',
    classNames: ['header-text-button'],
    attributeBindings: ['isDisabled:disabled', 'testId:data-test-id'],
    isDisabled: false,

    /**
     * Click action
     */
    click: function click(e) {
      var _this = this;

      e.preventDefault();
      this.set('isDisabled', true);
      var action = this.get('action');
      var param = this.get('param');

      if (action) {
        var promise;

        if (param) {
          promise = action(param);
        } else {
          promise = action();
        }

        if (promise) {
          promise.finally(function () {
            if (!_this.get('isDestroyed')) {
              _this.set('isDisabled', false);
            }
          });
        }
      }
    }
  });

  _exports.default = _default;
});