define("ember-composable-helpers/helpers/intersect", ["exports", "@ember/component/helper", "@ember/array", "ember-composable-helpers/utils/as-array"], function (_exports, _helper, _array, _asArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.intersect = intersect;
  _exports.default = void 0;

  function _toArray(arr) { return _arrayWithHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function intersect(_ref) {
    var _ref2 = _toArray(_ref),
        arrays = _ref2.slice(0);

    var confirmedArrays = (0, _asArray.default)(arrays).map(function (array) {
      return (0, _array.isArray)(array) ? array : [];
    }); // copied from https://github.com/emberjs/ember.js/blob/315ec6472ff542ac714432036cc96fe4bd62bd1f/packages/%40ember/object/lib/computed/reduce_computed_macros.js#L1063-L1100

    var results = confirmedArrays.pop().filter(function (candidate) {
      for (var i = 0; i < confirmedArrays.length; i++) {
        var found = false;
        var array = confirmedArrays[i];

        for (var j = 0; j < array.length; j++) {
          if (array[j] === candidate) {
            found = true;
            break;
          }
        }

        if (found === false) {
          return false;
        }
      }

      return true;
    });
    return results;
  }

  var _default = (0, _helper.helper)(intersect);

  _exports.default = _default;
});