define("@huvepharma/huvepharma-aviapp-components/templates/components/page-numbers/page-numbers", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Y36wly8T",
    "block": "[[[10,0],[14,0,\"pagination-centered\"],[12],[1,\"\\n  \"],[10,\"ul\"],[14,0,\"pagination\"],[12],[1,\"\\n    \"],[10,\"li\"],[15,0,[29,[\"page-item arrow prev\\n        \",[52,[30,0,[\"canStepBackward\"]],\"enabled-arrow\",\"disabled\"]]]],[12],[1,\"\\n      \"],[11,3],[24,6,\"#\"],[24,0,\"page-link\"],[4,[38,1],[\"click\",[28,[37,2],[[30,0,[\"incrementPage\"]],-1],null]],null],[12],[1,\"«\"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,0,[\"pageItems\"]]],null]],null],null,[[[41,[30,1,[\"dots\"]],[[[1,\"        \"],[10,\"li\"],[14,0,\"page-item dots disabled\"],[12],[1,\"\\n          \"],[10,1],[12],[1,\"...\"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[41,[30,1,[\"current\"]],[[[1,\"        \"],[10,\"li\"],[14,0,\"page-item active page-number\"],[12],[1,\"\\n          \"],[10,3],[14,6,\"#\"],[14,0,\"page-link\"],[12],[1,[30,1,[\"page\"]]],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,\"li\"],[14,0,\"page-item page-number\"],[12],[1,\"\\n          \"],[11,3],[24,6,\"#\"],[24,0,\"page-link\"],[4,[38,1],[\"click\",[28,[37,2],[[30,0,[\"pageClicked\"]],[30,1,[\"page\"]]],null]],null],[12],[1,[30,1,[\"page\"]]],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]]]],[1]],null],[1,\"    \"],[10,\"li\"],[15,0,[29,[\"page-item arrow next\\n        \",[52,[30,0,[\"canStepForward\"]],\"enabled-arrow\",\"disabled\"]]]],[12],[1,\"\\n      \"],[11,3],[24,6,\"#\"],[24,0,\"page-link\"],[4,[38,1],[\"click\",[28,[37,2],[[30,0,[\"incrementPage\"]],1],null]],null],[12],[1,\"»\"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"item\"],false,[\"if\",\"on\",\"fn\",\"each\",\"-track-array\"]]",
    "moduleName": "@huvepharma/huvepharma-aviapp-components/templates/components/page-numbers/page-numbers.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});