define("@huvepharma/huvepharma-aviapp-components/templates/components/form/field-autocomplete", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "BBljjsoH",
    "block": "[[[8,[39,0],[[4,[38,2],[\"focusout\",[28,[37,1],[[30,0],\"focusOut\"],null]],null],[4,[38,2],[\"focusin\",[28,[37,1],[[30,0],\"focusIn\"],null]],null]],[[\"@testId\",\"@value\",\"@placeholder\",\"@autocomplete\",\"@update\",\"@maxlength\",\"@class\"],[[30,1],[30,2],[30,3],\"off\",[28,[37,1],[[30,0],\"performAutocomplete\"],null],[30,4],[30,5]]],null],[1,\"\\n\"],[41,[30,0,[\"results\"]],[[[1,\"  \"],[10,0],[15,0,[29,[\"autocomplete-content \",[52,[51,[30,0,[\"isDisplayingResults\"]]],\"hidden\"]]]],[12],[1,\"\\n\"],[41,[30,2],[[[42,[28,[37,6],[[28,[37,6],[[30,0,[\"results\"]]],null]],null],null,[[[1,\"        \"],[11,0],[24,0,\"autocomplete-result\"],[4,[38,1],[[30,0],\"onSetValue\",[30,6]],null],[12],[1,[28,[35,7],[[30,6],[30,2]],null]],[13],[1,\"\\n\"]],[6]],null]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[\"@testId\",\"@value\",\"@placeholder\",\"@maxlength\",\"@class\",\"item\"],false,[\"form/huve-field-input\",\"action\",\"on\",\"if\",\"unless\",\"each\",\"-track-array\",\"highlight-search\"]]",
    "moduleName": "@huvepharma/huvepharma-aviapp-components/templates/components/form/field-autocomplete.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});