define("@huvepharma/huvepharma-aviapp-components/templates/components/base-overlay", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "LEskIB1f",
    "block": "[[[41,[33,1],[[[6,[39,2],null,[[\"to\"],[\"overlay-wormhole\"]],[[\"default\"],[[[[1,\"    \"],[10,0],[14,0,\"base-overlay\"],[12],[1,\"\\n      \"],[18,1,null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]]],[]],null]],[\"&default\"],false,[\"if\",\"show\",\"ember-wormhole\",\"yield\"]]",
    "moduleName": "@huvepharma/huvepharma-aviapp-components/templates/components/base-overlay.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});