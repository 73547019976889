define("@huvepharma/huvepharma-aviapp-components/components/header/index", ["exports", "@ember/object", "@ember/service", "@ember/component", "@huvepharma/huvepharma-aviapp-components/templates/components/header"], function (_exports, _object, _service, _component, _header) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    isMenuOpen: false,
    homePage: '/',
    router: (0, _service.inject)(),
    // Get the current path
    currentPath: (0, _object.computed)('router.currentRouteName', function () {
      return this.get('router.currentRouteName');
    }),
    layout: _header.default,
    toggleMenu: function toggleMenu() {
      this.set('isMenuOpen', !this.get('isMenuOpen'));
    },
    closeMenu: function closeMenu() {
      this.set('isMenuOpen', false);
    },
    actions: {
      switchCompany: function switchCompany(company) {
        this.switchCompany(company);
        this.closeMenu();
      },
      onToggleMenu: function onToggleMenu() {
        this.toggleMenu();
      },
      menuItemClicked: function menuItemClicked(itemOnClick, route) {
        if (itemOnClick) {
          itemOnClick(route);
        }

        this.closeMenu();
      }
    }
  });

  _exports.default = _default;
});