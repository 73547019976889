define("@huvepharma/huvepharma-aviapp-components/templates/components/buttons/icon-button", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "zIZuVdih",
    "block": "[[[10,3],[15,6,[36,0]],[15,0,[29,[\"button icon-button \",[52,[33,2],\"button-disabled\"],\" \",[52,[28,[37,3],[[33,4],[28,[37,5],[[33,2]],null]],null],[28,[37,6],[\"button-\",[33,4]],null]],\" \",[52,[33,7],\"button-large\"]]]],[15,\"data-test-id\",[36,8]],[12],[1,\"\\n  \"],[10,1],[12],[10,\"svg\"],[12],[10,\"use\"],[15,\"xlink:href\",[29,[[36,9]]],\"http://www.w3.org/1999/xlink\"],[12],[13],[13],[10,1],[14,0,\"hidden-md hidden-sm hidden-xs\"],[12],[1,[34,10]],[13],[13],[1,\"\\n\"],[13]],[],false,[\"link\",\"if\",\"disabled\",\"and\",\"color\",\"not\",\"concat\",\"isLarge\",\"dataTestId\",\"icon\",\"label\"]]",
    "moduleName": "@huvepharma/huvepharma-aviapp-components/templates/components/buttons/icon-button.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});