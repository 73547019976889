define("@huvepharma/huvepharma-aviapp-components/templates/components/farms/farm-item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "hRfsgtrk",
    "block": "[[[10,3],[15,6,[29,[[36,0]]]],[15,0,[29,[[36,1]]]],[15,\"data-test-id\",[29,[[36,2]]]],[15,\"onclick\",[28,[37,3],[[30,0],[33,4]],null]],[12],[1,\"\\n  \"],[10,0],[14,0,\"farm-logo\"],[12],[1,\"\\n    \"],[10,\"svg\"],[12],[1,\"\\n      \"],[10,\"use\"],[14,\"xlink:href\",\"#farm\",\"http://www.w3.org/1999/xlink\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"farm-name\"],[12],[1,\"\\n    \"],[10,2],[12],[1,[28,[35,5],null,[[\"text\",\"lines\",\"interactive\"],[[33,6],[33,7],false]]]],[13],[1,\"\\n\"],[41,[33,9],[[[1,\"    \"],[10,\"em\"],[14,0,\"desc\"],[12],[1,\"\\n      \"],[10,\"svg\"],[14,0,\"flock-card-info-icon\"],[12],[1,\"\\n        \"],[10,\"use\"],[14,\"xlink:href\",\"#complex\",\"http://www.w3.org/1999/xlink\"],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[1,[34,9]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"],[41,[33,10],[[[1,\"  \"],[10,\"svg\"],[14,0,\"warning\"],[12],[1,\"\\n    \"],[10,\"use\"],[14,\"xlink:href\",\"#warning\",\"http://www.w3.org/1999/xlink\"],[12],[13],[1,\"\\n    \"],[10,\"title\"],[12],[1,\"\\\"\"],[1,[28,[35,11],[\"farms_label_archivedsoon\"],null]],[1,\"\\\"\"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[],false,[\"link\",\"selectedClass\",\"testId\",\"action\",\"onClick\",\"line-clamp\",\"text\",\"lines\",\"if\",\"complex\",\"hasToBeArchivedSoon\",\"t\"]]",
    "moduleName": "@huvepharma/huvepharma-aviapp-components/templates/components/farms/farm-item.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});