define("@huvepharma/huvepharma-aviapp-components/components/tables/gfr-raw-table", ["exports", "@ember/component", "@ember/object", "@huvepharma/huvepharma-aviapp-components/templates/components/tables/gfr-raw-table"], function (_exports, _component, _object, _gfrRawTable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }

  var _default = _component.default.extend({
    layout: _gfrRawTable.default,
    rootTestId: 'gfr-raw-table',
    days: (0, _object.computed)('amount', function () {
      return _toConsumableArray(Array((0, _object.get)(this, 'amount')).keys()).map(function (x) {
        return x;
      });
    }),
    actions: {
      update: function update(id, index, value) {
        var _this$update;

        (_this$update = this.update) === null || _this$update === void 0 ? void 0 : _this$update.call(this, id, index, value);
      }
    }
  });

  _exports.default = _default;
});