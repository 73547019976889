define("@huvepharma/huvepharma-aviapp-components/components/loadable/loadable-link", ["exports", "@huvepharma/huvepharma-aviapp-components/components/loadable/loadable-base"], function (_exports, _loadableBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _loadableBase.default.extend({
    tagName: 'a',
    classNames: ['loadable-link'],
    isLink: true
  });

  _exports.default = _default;
});