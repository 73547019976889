define("@huvepharma/huvepharma-aviapp-components/templates/components/header/detail-header", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Vjok8RWf",
    "block": "[[[6,[39,0],null,[[\"classes\",\"testId\",\"loadContent\",\"isDisabled\",\"spinnerClass\"],[\"detail-header-close\",[28,[37,1],[[33,2],\"-close-button\"],null],[33,3],[33,4],\"\"]],[[\"default\"],[[[[1,\"  \"],[10,\"svg\"],[14,0,\"icon-cancel\"],[12],[10,\"use\"],[14,\"xlink:href\",\"#cancel\",\"http://www.w3.org/1999/xlink\"],[12],[13],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[10,0],[14,0,\"detail-header-title\"],[15,\"data-test-id\",[29,[[36,2],\"-header\"]]],[12],[1,\"\\n  \"],[10,\"h1\"],[15,\"data-test-id\",[29,[[36,2],\"-title\"]]],[12],[1,\"\\n\"],[41,[33,6],[[[1,\"      \"],[1,[28,[35,7],null,[[\"prefix\",\"value\",\"hideSpinner\",\"noSaving\",\"testId\",\"onSave\",\"maxLength\",\"isInlineEditable\"],[[33,8],[33,9],[33,10],[33,11],[28,[37,1],[[33,2],\"-editable-title\"],null],[33,12],[33,13],true]]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[34,9]],[1,\"\\n\"]],[]]],[41,[33,14],[[[1,\"      \"],[11,0],[24,0,\"editable-input-icon-wrapper\"],[24,\"data-test-id\",\"header-edit-action\"],[4,[38,15],[[30,0],[33,14]],null],[12],[1,\"\\n        \"],[10,\"svg\"],[14,0,\"editable-input-icon\"],[12],[1,\"\\n          \"],[10,\"use\"],[14,\"xlink:href\",\"#edit-circle\",\"http://www.w3.org/1999/xlink\"],[12],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"detail-header-actions\"],[12],[1,\"\\n  \"],[18,1,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"loadable/huve-button\",\"concat\",\"dataTestId\",\"close\",\"isCloseDisabled\",\"if\",\"isTitleEditable\",\"form/editable-input\",\"editableTitlePrefix\",\"headerTitle\",\"hideSpinner\",\"noSaving\",\"onSave\",\"maxLength\",\"editAction\",\"action\",\"yield\"]]",
    "moduleName": "@huvepharma/huvepharma-aviapp-components/templates/components/header/detail-header.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});