define("@huvepharma/huvepharma-aviapp-components/templates/components/modal/modal-base", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "SWumOeIZ",
    "block": "[[[41,[33,1],[[[6,[39,2],null,[[\"to\"],[\"modal-wormhole\"]],[[\"default\"],[[[[1,\"    \"],[10,0],[15,0,[29,[\"mod-modal is-visible \",[52,[51,[33,4]],\"mod-modal-default\"]]]],[15,\"data-test-id\",[29,[[36,5]]]],[12],[1,\"\\n      \"],[11,1],[24,0,\"mod-modal-overlay\"],[4,[38,6],[[30,0],[28,[37,7],[[33,8]],null]],null],[12],[13],[1,\"\\n      \"],[10,0],[15,0,[29,[\"mod-modal-content-wrapper \",[36,9]]]],[12],[1,\"\\n        \"],[10,0],[15,0,[29,[\"mod-modal-content \",[36,10]]]],[12],[1,\"\\n\"],[41,[33,11],[[[1,\"            \"],[10,\"header\"],[12],[1,\"\\n              \"],[10,\"h2\"],[14,\"data-test-id\",\"modal-title\"],[12],[1,[34,12]],[13],[1,\"\\n\"],[41,[33,13],[[[1,\"                \"],[11,0],[24,0,\"modal-close-container\"],[24,\"data-test-id\",\"modal-close\"],[4,[38,6],[[30,0],[33,13]],null],[12],[1,\"\\n                  \"],[10,\"svg\"],[14,0,\"modal-close\"],[12],[1,\"\\n                    \"],[10,\"use\"],[14,\"xlink:href\",\"#cancel\",\"http://www.w3.org/1999/xlink\"],[12],[13],[1,\"\\n                  \"],[13],[1,\"\\n                \"],[13],[1,\"\\n\"]],[]],null],[1,\"            \"],[13],[1,\"\\n\"]],[]],null],[1,\"          \"],[18,1,null],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]]],[]],null]],[\"&default\"],false,[\"if\",\"show\",\"ember-wormhole\",\"unless\",\"noDefault\",\"testIdValue\",\"action\",\"optional\",\"overlayAction\",\"class\",\"contentClass\",\"header\",\"headerText\",\"headerCloseAction\",\"yield\"]]",
    "moduleName": "@huvepharma/huvepharma-aviapp-components/templates/components/modal/modal-base.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});