define("@huvepharma/huvepharma-aviapp-components/helpers/gte", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var gte = function gte(params) {
    return params[0] >= params[1];
  };

  var _default = (0, _helper.helper)(gte);

  _exports.default = _default;
});