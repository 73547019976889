define("@huvepharma/huvepharma-aviapp-components/templates/components/toggle/toggle-visible", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "tKJr5nkq",
    "block": "[[[18,1,[[28,[37,1],null,[[\"visible\",\"toggle\"],[[33,2],[28,[37,3],[[30,0],\"toggle\"],null]]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"visible\",\"action\"]]",
    "moduleName": "@huvepharma/huvepharma-aviapp-components/templates/components/toggle/toggle-visible.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});