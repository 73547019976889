define("@huvepharma/huvepharma-aviapp-components/components/character-counter", ["exports", "@ember/object", "@ember/component", "@huvepharma/huvepharma-aviapp-components/templates/components/character-counter"], function (_exports, _object, _component, _characterCounter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    layout: _characterCounter.default,
    class: ['character-counter'],
    characterLimit: 0,
    characterThreshold: 0,
    characterCount: (0, _object.computed)('value', function () {
      if ((0, _object.get)(this, 'value')) {
        return (0, _object.get)(this, 'value').length || 0;
      }

      return 0;
    }),
    charactersLeft: (0, _object.computed)('characterCount', function () {
      return (0, _object.get)(this, 'characterLimit') - (0, _object.get)(this, 'characterCount');
    }),
    characterCountVisible: (0, _object.computed)('charactersLeft', function () {
      return (0, _object.get)(this, 'charactersLeft') < (0, _object.get)(this, 'characterLimit');
    }),
    threshold: (0, _object.computed)('characterCount', function () {
      return (0, _object.get)(this, 'charactersLeft') <= (0, _object.get)(this, 'characterThreshold');
    })
  });

  _exports.default = _default;
});