define("@huvepharma/huvepharma-aviapp-components/templates/components/loadable/loadable-content", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "BAiiinb3",
    "block": "[[[10,0],[14,0,\"loading-big\"],[12],[1,\"\\n  \"],[10,0],[15,0,[29,[\"spinner-icon \",[36,0]]]],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"content\"],[12],[1,\"\\n  \"],[18,1,[\"content\"]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"spinnerClass\",\"yield\"]]",
    "moduleName": "@huvepharma/huvepharma-aviapp-components/templates/components/loadable/loadable-content.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});