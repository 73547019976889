define("@huvepharma/huvepharma-aviapp-components/services/screen", ["exports", "ember-screen"], function (_exports, _emberScreen) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberScreen.default.extend({
    isExtraSmallAndUp: (0, _emberScreen.breakpoint)('(min-width: 320px)'),
    isSmallAndUp: (0, _emberScreen.breakpoint)('(min-width: 568px)'),
    isMediumAndUp: (0, _emberScreen.breakpoint)('(min-width: 768px)'),
    isExtraSmallAndDown: (0, _emberScreen.breakpoint)('(max-width: 319.98px)'),
    isSmallAndDown: (0, _emberScreen.breakpoint)('(max-width: 567.98px)'),
    isMediumAndDown: (0, _emberScreen.breakpoint)('(max-width: 767.98px)')
  });

  _exports.default = _default;
});