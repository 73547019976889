define("ember-changeset/utils/merge-deep", ["exports", "validated-changeset"], function (_exports, _validatedChangeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = mergeDeep;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  function isMergeableObject(value) {
    return isNonNullObject(value) && !isSpecial(value);
  }

  function isNonNullObject(value) {
    return !!value && _typeof(value) === 'object';
  }

  function isSpecial(value) {
    var stringValue = Object.prototype.toString.call(value);
    return stringValue === '[object RegExp]' || stringValue === '[object Date]';
  } // Reconsider when enumerable symbols are removed - https://github.com/emberjs/ember.js/commit/ef0e277533b3eab01e58d68b79d7e37d8b11ee34
  // function getEnumerableOwnPropertySymbols(target) {
  //   return Object.getOwnPropertySymbols
  //     ? Object.getOwnPropertySymbols(target).filter(symbol => {
  //       return Object.prototype.propertyIsEnumerable.call(target, symbol)
  //     })
  //     : [];
  // }


  function getKeys(target) {
    return Object.keys(target); // .concat(getEnumerableOwnPropertySymbols(target))
  }

  function propertyIsOnObject(object, property) {
    try {
      return property in object;
    } catch (_) {
      return false;
    }
  } // Ember Data models don't respond as expected to foo.hasOwnProperty, so we do a special check


  function hasEmberDataProperty(target, key, options) {
    var fields = options.safeGet(target, 'constructor.fields');
    return fields instanceof Map && fields.has(key);
  } // Protects from prototype poisoning and unexpected merging up the prototype chain.


  function propertyIsUnsafe(target, key, options) {
    if (hasEmberDataProperty(target, key, options)) {
      return false;
    }

    return propertyIsOnObject(target, key) && // Properties are safe to merge if they don't exist in the target yet,
    !(Object.prototype.hasOwnProperty.call(target, key) && Object.prototype.propertyIsEnumerable.call(target, key)); // and also unsafe if they're nonenumerable.
  }
  /**
   * DFS - traverse depth first until find object with `value`.  Then go back up tree and try on next key
   * Need to exhaust all possible avenues.
   *
   * @method buildPathToValue
   */


  function buildPathToValue(source, options, kv, possibleKeys) {
    Object.keys(source).forEach(function (key) {
      var possible = source[key];

      if (possible && (0, _validatedChangeset.isChange)(possible)) {
        kv[[].concat(_toConsumableArray(possibleKeys), [key]).join('.')] = (0, _validatedChangeset.getChangeValue)(possible);
        return;
      }

      if (possible && _typeof(possible) === 'object') {
        buildPathToValue(possible, options, kv, [].concat(_toConsumableArray(possibleKeys), [key]));
      }
    });
    return kv;
  }
  /**
   * `source` will always have a leaf key `value` with the property we want to set
   *
   * @method mergeTargetAndSource
   */


  function mergeTargetAndSource(target, source, options) {
    getKeys(source).forEach(function (key) {
      // proto poisoning.  So can set by nested key path 'person.name'
      if (propertyIsUnsafe(target, key, options)) {
        // if safeSet, we will find keys leading up to value and set
        if (options.safeSet) {
          var kv = buildPathToValue(source, options, {}, []); // each key will be a path nested to the value `person.name.other`

          if (Object.keys(kv).length > 0) {
            // we found some keys!
            for (key in kv) {
              var val = kv[key];
              options.safeSet(target, key, val);
            }
          }
        }

        return;
      } // else safe key on object


      if (propertyIsOnObject(target, key) && isMergeableObject(source[key]) && !(0, _validatedChangeset.isChange)(source[key])) {
        options.safeSet(target, key, mergeDeep(options.safeGet(target, key), options.safeGet(source, key), options));
      } else {
        var next = source[key];

        if ((0, _validatedChangeset.isChange)(next)) {
          return options.safeSet(target, key, (0, _validatedChangeset.getChangeValue)(next));
        } // if just some normal leaf value, then set


        return options.safeSet(target, key, (0, _validatedChangeset.normalizeObject)(next));
      }
    });
    return target;
  }
  /**
   * goal is to mutate target with source's properties, ensuring we dont encounter
   * pitfalls of { ..., ... } spread syntax overwriting keys on objects that we merged
   *
   * This is also adjusted for Ember peculiarities.  Specifically `options.safeSet` will allows us
   * to handle properties on Proxy objects (that aren't the target's own property)
   *
   * @method mergeDeep
   */


  function mergeDeep(target, source) {
    var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

    options.safeGet = options.safeGet || function (obj, key) {
      return obj[key];
    };

    options.safeSet = options.safeSet || function (obj, key, value) {
      return obj[key] = value;
    };

    var sourceIsArray = Array.isArray(source);
    var targetIsArray = Array.isArray(target);
    var sourceAndTargetTypesMatch = sourceIsArray === targetIsArray;

    if (!sourceAndTargetTypesMatch) {
      return source;
    } else if (sourceIsArray) {
      return source;
    }

    try {
      return mergeTargetAndSource(target, source, options);
    } catch (e) {
      // this is very unlikely to be hit but lets throw an error otherwise
      throw new Error('Unable to `mergeDeep` with your data. Are you trying to merge two ember-data objects? Please file an issue with ember-changeset.');
    }
  }
});