define("@huvepharma/huvepharma-aviapp-components/components/about/index", ["exports", "@ember/component", "@ember/object", "moment", "@huvepharma/huvepharma-aviapp-components/templates/components/about"], function (_exports, _component, _object, _moment, _about) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    layout: _about.default,
    tagName: '',
    disableDeletion: false,
    preventDefault: false,
    isDeleteAccountModalVisible: false,
    isOwnerWarningModalVisible: false,
    instructionVideosLink: 'https://vimeopro.com/huvepharma/aviapp-instruction-video',
    whatsNewLink: 'https://vimeopro.com/huvepharma/whats-new',
    actions: {
      openLink: function openLink(link, label) {
        var shouldOpenWindow = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

        // Call openLink action on parent about page that will
        // log GA event and - if on Xamarin app - will open
        // link in an external browser
        if ((0, _object.get)(this, 'openLink') && typeof (0, _object.get)(this, 'openLink') === 'function') {
          (0, _object.get)(this, 'openLink')(link, label);
        } // If the action is called from an element that is not an
        // anchor tag we still want it to open in another window,
        // so we call window.open, unless we're using the offline
        // Xamarin app.


        if (shouldOpenWindow && !(0, _object.get)(this, 'preventDefault')) {
          window.open(link);
        }
      },

      /**
       * Call /delete-account-request and close the modal.
       * Returns an error if a request has already previously been made.
       */
      onDeleteAccount: function onDeleteAccount() {
        var _this = this;

        (0, _object.set)(this, 'me.deletionRequestAt', _moment.default.utc().toDate());
        return (0, _object.get)(this, 'me').save().catch(function (error) {
          (0, _object.get)(_this, 'me').rollbackAttributes();
          console.error(error);
        }).finally(function () {
          return (0, _object.set)(_this, 'isDeleteAccountModalVisible', false);
        });
      },

      /**
       * If owner of at least one company, show warning modal instead of deletion modal
       */
      openDeleteAccountModal: function openDeleteAccountModal() {
        var _this$verifyIfAllowed;

        // Verifies if we are allowed to execute this action in
        // the current context of the application (eg. xamarin offline)
        if (((_this$verifyIfAllowed = this.verifyIfAllowed) === null || _this$verifyIfAllowed === void 0 ? void 0 : _this$verifyIfAllowed.call(this)) === false) {
          return;
        }

        var companies = (0, _object.get)(this, 'me.companies');
        var isOwner = (0, _object.get)(companies.filter(function (company) {
          return (0, _object.get)(company, 'isCompanyOwner');
        }), 'length') > 0;
        (0, _object.set)(this, isOwner ? 'isOwnerWarningModalVisible' : 'isDeleteAccountModalVisible', true);
      }
    }
  });

  _exports.default = _default;
});