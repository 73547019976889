define("@huvepharma/huvepharma-aviapp-components/components/loadable/loadable-content", ["exports", "@ember/component", "@huvepharma/huvepharma-aviapp-components/templates/components/loadable/loadable-content"], function (_exports, _component, _loadableContent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A component with content being loaded asynchronously. It simply yields its
   * content depending on the isLoading flag.
   */
  var _default = _component.default.extend({
    layout: _loadableContent.default,
    classNames: ['loadable-content'],
    classNameBindings: ['isLoading:show-loading'],
    spinnerClass: 'primary'
  });

  _exports.default = _default;
});