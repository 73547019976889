define("@huvepharma/huvepharma-aviapp-components/helpers/build-test-id", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var testIdHelper = function testIdHelper(params) {
    return params.join('').toLowerCase().replace(/['"]/g, '').replace(/\s/g, '-');
  };

  var _default = (0, _helper.helper)(testIdHelper);

  _exports.default = _default;
});