define("@huvepharma/huvepharma-aviapp-components/mixins/apply-scroll-id", ["exports", "@ember/object/mixin", "@ember/object"], function (_exports, _mixin, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    init: function init() {
      this._super.apply(this, arguments);

      if ((0, _object.get)(this, 'scrollId')) {
        var attributeBindings = (0, _object.get)(this, 'attributeBindings') || [];
        (0, _object.set)(this, 'attributeBindings', attributeBindings.concat('scrollId:data-scroll-id'));
      }
    }
  });

  _exports.default = _default;
});