define("@huvepharma/huvepharma-aviapp-components/constants/chart-styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var defaults = {
    colours: {
      backgroundColor: '#ffffff',
      darkLabelColor: '#415e6a',
      lightLabelColor: '#a2afb4'
    },
    fonts: {
      labelFont: "'Mulish', Arial, sans-serif"
    }
  };
  var ChartStyles = {
    axisLineStyle: {
      lineColor: defaults.colours.lightLabelColor,
      lineWidth: 1.5
    },
    axisTitleStyle: {
      fontFamily: defaults.fonts.labelFont,
      fontWeight: '400',
      color: defaults.colours.darkLabelColor
    },
    backgroundColor: defaults.colours.backgroundColor,
    benchmarkLineStyle: {
      dashStyle: 'longdash',
      marker: {
        enabled: false
      }
    },
    columnHeight: 95,
    colours: defaults.colours,
    fonts: defaults.fonts,
    titleStyle: {
      fontFamily: defaults.fonts.labelFont,
      fontWeight: '400',
      fontSize: '17px',
      textAlign: 'right'
    },
    darkLabelStyle: {
      fontFamily: defaults.fonts.labelFont,
      fontSize: '13px',
      fontWeight: '400',
      color: defaults.colours.darkLabelColor
    },
    dataLabelStyle: {
      fontFamily: defaults.fonts.labelFont,
      fontSize: '14px',
      textShadow: 'none',
      textOutline: 'none'
    },
    height: 500,
    lightLabelStyle: {
      fontFamily: defaults.fonts.labelFont,
      fontSize: '17px',
      textOverflow: 'none',
      fontWeight: '400',
      color: defaults.colours.lightLabelColor
    },
    margins: {
      left: 60,
      right: 50,
      top: 40
    },
    leftYAxisTitleStyle: {
      align: 'high',
      textAlign: 'left',
      rotation: 0,
      text: null,
      x: 0,
      y: -20,
      offset: 0
    },
    rightYAxisTitleStyle: {
      align: 'high',
      textAlign: 'right',
      rotation: 0,
      text: null,
      x: 0,
      y: -20,
      offset: 0
    },
    legendStyle: {
      fontFamily: "'Mulish', sans-serif",
      fontSize: '16px',
      fontWeight: 'normal',
      color: '#a2afb4'
    },
    bar: {
      height: 24
    }
  };
  var _default = ChartStyles;
  _exports.default = _default;
});