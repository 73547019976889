define("@huvepharma/huvepharma-aviapp-components/templates/components/form/custom-selector", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "ihPB/EZA",
    "block": "[[[11,\"input\"],[16,\"data-test-id\",[30,1]],[16,1,[30,1]],[16,0,[29,[\"custom-selector-input\\n    \",[52,[30,2],\"error\"],\"\\n    \",[52,[30,3],\"custom-selector-input-with-icon\"]]]],[16,\"placeholder\",[29,[[30,4]]]],[16,2,[30,5]],[24,\"readonly\",\"\"],[16,\"disabled\",[29,[[52,[30,6],\"disabled\"]]]],[4,[38,1],[\"click\",[30,7]],null],[12],[13],[1,\"\\n\"],[10,\"svg\"],[12],[10,\"use\"],[15,\"xlink:href\",[29,[\"#\",[30,3]]],\"http://www.w3.org/1999/xlink\"],[12],[13],[13],[1,\"\\n\"],[10,1],[14,0,\"dropdown-button\"],[12],[1,\"\\n  \"],[10,\"svg\"],[12],[10,\"use\"],[14,\"xlink:href\",\"#arrow-down\",\"http://www.w3.org/1999/xlink\"],[12],[13],[13],[1,\"\\n\"],[13]],[\"@testId\",\"@error\",\"@icon\",\"@placeholder\",\"@value\",\"@disabled\",\"@action\"],false,[\"if\",\"on\"]]",
    "moduleName": "@huvepharma/huvepharma-aviapp-components/templates/components/form/custom-selector.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});