define("@huvepharma/huvepharma-aviapp-components/utils/fingerprint", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isMobileSafari = isMobileSafari;
  _exports.isTablet = isTablet;
  _exports.isTabletPortrait = isTabletPortrait;
  _exports.getMobileSafariApproxKeyboardHeight = getMobileSafariApproxKeyboardHeight;

  /**
   * Determines if the browser is mobile Safari (iPod, iPhone, iPad)
   * @returns {Boolean} true if a mobile Safari browser
   */
  function isMobileSafari() {
    return navigator.userAgent.match(/(iPod|iPhone|iPad)/) && navigator.userAgent.match(/AppleWebKit/);
  }
  /**
   * Determines if the browser is tablet or mobile based
   * @returns {Boolean} true if the device is Android, iOS, Blackberry etc.
   */


  function isTablet() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }
  /**
   * Determines if the tablet is in portrait orientation
   * @returns {Boolean} true if the window height is greater than the window width
   */


  function isTabletPortrait() {
    return isTablet() && window.innerHeight > window.innerWidth;
  }
  /**
   * Determines the Mobile Safari Keyboard Height based off approximations & assumptions
   * TODO: Probably should be revised with something more concrete.
   * If using the XamarinApp we could use hooks via the extern. interface.
   * But when using the pure iOS safari browser we cannot know this information
   * @returns {Number} The height of the iOS keyboard
   */


  function getMobileSafariApproxKeyboardHeight(keyboardSize) {
    if (!isMobileSafari()) {
      return 0;
    }

    return isTabletPortrait() ? keyboardSize.portrait + 50 : keyboardSize.landscape + 50;
  }
});