define("@huvepharma/huvepharma-aviapp-components/filters/programs", ["exports", "@ember/object", "moment", "moment-range", "@huvepharma/huvepharma-aviapp-components/utils/filter"], function (_exports, _object, _moment, _momentRange, _filter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getTree;
  _exports.mapNodesToFilters = _exports.applicationPeriodFilter = _exports.searchFilter = _exports.otherTypeFilter = _exports.cocciTypeFilter = _exports.PROGRAM_TYPE = _exports.APPLICATION_PERIOD = _exports.OTHER_TYPE = _exports.COCCI_TYPE = void 0;
  var moment = (0, _momentRange.extendMoment)(_moment.default);
  var ROOT = 'root';
  var COCCI_TYPE = 'cocci';
  _exports.COCCI_TYPE = COCCI_TYPE;
  var OTHER_TYPE = 'other';
  _exports.OTHER_TYPE = OTHER_TYPE;
  var APPLICATION_PERIOD = 'application_period';
  _exports.APPLICATION_PERIOD = APPLICATION_PERIOD;
  var PROGRAM_TYPE = 'label_program_type';
  /**
   * Filters using the cocciType property
   * @param {Object} filter
   * @param {Object} item
   * @returns {Boolean}
   */

  _exports.PROGRAM_TYPE = PROGRAM_TYPE;

  var cocciTypeFilter = function cocciTypeFilter(filter, item) {
    return (0, _object.get)(item, 'cocciType') === (0, _object.get)(filter, 'id');
  };
  /**
   * Checks if the item has any phases with the program in the filter
   * @param {Object} filter
   * @param {Object} item
   * @returns {Boolean}
   */


  _exports.cocciTypeFilter = cocciTypeFilter;

  var otherTypeFilter = function otherTypeFilter(filter, item) {
    return (0, _object.get)(item, 'phases').some(function (phase) {
      return (0, _object.get)(phase, 'program') === (0, _object.get)(filter, 'id');
    });
  };
  /**
   * Filters for a given search value on name or brand
   * @param {Object} filter
   * @param {Object} item
   * @returns {Boolean}
   */


  _exports.otherTypeFilter = otherTypeFilter;

  var searchFilter = function searchFilter(filter, item) {
    var search = (0, _object.get)(filter, 'label').toLowerCase();
    var name = (0, _object.get)(item, 'name').toLowerCase();
    var nameResult = name.includes(search);
    var brandResult = (0, _object.get)(item, 'phases').some(function (phase) {
      return ((0, _object.get)(phase, 'brand') || '').toLowerCase().includes(search);
    });
    return nameResult || brandResult;
  };
  /**
   * Takes a program, an upper limit (start) and a lower limit (end)
   * and checks if the program applies to that filter
   * @param {Date} start
   * @param {Date} end
   * @param {Object} Program
   */


  _exports.searchFilter = searchFilter;

  var applicationPeriodFilter = function applicationPeriodFilter(start, end, program) {
    var startDate = moment.utc((0, _object.get)(program, 'startDate'));
    var endDate = moment.utc((0, _object.get)(program, 'endDate'));
    var startLimit = moment.utc(start);
    var endLimit = moment.utc(end); // If the startLimit is after the endLimit, filter on
    // programs where only the start limit lies within
    // the application period

    if (startLimit.isAfter(endLimit)) {
      return startLimit.isBetween(startDate, endDate);
    } // Otherwise the ranges just need to overlap


    return moment.range(startDate, endDate).overlaps(moment.range(startLimit, endLimit), {
      adjacent: true
    });
  };
  /**
   * Maps nodes from a filterTree to
   * filters to be used by the filterList util function
   * (with eg. optional callback)
   * @param {Node} nodes
   * @param {String} type
   * @param {Date} start
   * @param {Date} end
   * @returns {Array}
   */


  _exports.applicationPeriodFilter = applicationPeriodFilter;

  var mapNodesToFilters = function mapNodesToFilters(nodes) {
    var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : COCCI_TYPE;
    var start = arguments.length > 2 ? arguments[2] : undefined;
    var end = arguments.length > 3 ? arguments[3] : undefined;
    var filters = nodes.reduce(function (acc, node) {
      if ((0, _object.get)(node, 'id') === APPLICATION_PERIOD) {
        acc[APPLICATION_PERIOD] = {
          callback: applicationPeriodFilter.bind(null, start, end)
        };
      } else if ((0, _object.get)(node, 'type') === PROGRAM_TYPE) {
        if (!(0, _object.get)(acc, PROGRAM_TYPE)) {
          acc[PROGRAM_TYPE] = [];
        }

        acc[PROGRAM_TYPE].push({
          callback: type === COCCI_TYPE ? cocciTypeFilter.bind(null, node) : otherTypeFilter.bind(null, node)
        });
      } else if ((0, _object.get)(node, 'id') === _filter.SEARCH_FILTER) {
        acc[_filter.SEARCH_FILTER] = {
          callback: searchFilter.bind(null, node)
        };
      }

      return acc;
    }, {});
    return Object.values(filters);
  };
  /**
   * Creates the filter tree data for either cocci or other programs
   * @param {String} type  cocci or other
   * @param {Array<Object>} otherPrograms (optional)
   * @param {String} applicationPeriodLabel the label for the application period can be overwritten
   * @returns {Object}
   */


  _exports.mapNodesToFilters = mapNodesToFilters;

  function getTree() {
    var type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : COCCI_TYPE;
    var otherPrograms = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
    var useSecondaryPeriodLabel = arguments.length > 2 ? arguments[2] : undefined;
    var cocciChildren = [{
      id: 'cocci',
      label: 'label_cocci_program',
      type: PROGRAM_TYPE
    }, {
      id: 'vaccination',
      label: 'label_vaccination',
      type: PROGRAM_TYPE
    }];
    var otherChildren = otherPrograms.map(function (program) {
      return {
        id: (0, _object.get)(program, 'id'),
        label: "flocks_label_program_".concat((0, _object.get)(program, 'id').toLowerCase()),
        type: 'label_program_type'
      };
    });
    return {
      type: type,
      id: ROOT,
      children: [{
        id: APPLICATION_PERIOD,
        label: useSecondaryPeriodLabel ? 'label_applicable' : 'label_application_period_months',
        isSelected: true
      }, {
        id: PROGRAM_TYPE,
        label: 'label_program_type',
        children: type === COCCI_TYPE ? cocciChildren : otherChildren
      }]
    };
  }
});