define("@huvepharma/huvepharma-aviapp-components/utils/charts/visual-chart-helpers", ["exports", "@huvepharma/huvepharma-aviapp-components/utils/color-helper"], function (_exports, _colorHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.styleChildBars = _exports.removeBgPlotBand = _exports.setBgPlotBand = _exports.getNewChartHeight = _exports.PARENT_HEIGHT = void 0;
  var PARENT_HEIGHT = 30;
  _exports.PARENT_HEIGHT = PARENT_HEIGHT;
  var CHILD_HEIGHT = 18;
  var CHART_SPACING = 2;
  /**
   * Recalculate height of the chart
   *
   * @param Number parentCount
   * @param Number childCount
   */

  var getNewChartHeight = function getNewChartHeight() {
    var parentCount = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
    var childCount = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    var totalParentHeight = PARENT_HEIGHT * parentCount;
    var totalChildHeight = PARENT_HEIGHT * childCount;
    var totalCount = parentCount + childCount;
    var spacingBetweenBars = (totalCount - 1) * CHART_SPACING;
    var axisHeights = 154; // Not sure why, but there's some additional height creep per added bar

    var additionalHeight = totalCount * 1.2;
    return totalParentHeight + totalChildHeight + spacingBetweenBars + axisHeights + additionalHeight;
  };
  /**
   * Sets a grey plot band behind the children bars
   *
   * @param Object chartXAxis: first xAxis of the Highchart chart xAxis array
   * @param Number from
   * @param Number to
   * @param String id
   * @param String color
   */


  _exports.getNewChartHeight = getNewChartHeight;

  var setBgPlotBand = function setBgPlotBand(chartXAxis, from, to, id) {
    var color = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : '#E6EDEF';
    chartXAxis.addPlotBand({
      id: id,
      from: from - 0.5,
      to: to + 0.5,
      color: color
    });
  };
  /**
   * Remove grey plot band behind children bars
   *
   * @param Object chart: Highcharts chart object
   * @param String id
   */


  _exports.setBgPlotBand = setBgPlotBand;

  var removeBgPlotBand = function removeBgPlotBand(chart, id) {
    if (chart.xAxis && chart.xAxis[0]) {
      chart.xAxis[0].removePlotBand(id);
    }
  };
  /**
   * Styles the child bars of a collapsible chart by lighting the colors and
   * giving a slightly smaller bar height on each individual datapoint of those
   * bars.
   *
   * @param {Object} category: The matching category of this datapoint
   * @param {Object} datapoint: A point of data in the content array, which will
   *  evnetually be parsed to series (see chart-structure.js)
   */


  _exports.removeBgPlotBand = removeBgPlotBand;

  var styleChildBars = function styleChildBars(category, datapoint) {
    if (category.parent && datapoint.additionalData && !datapoint.additionalData.styled) {
      if (datapoint.additionalData.color) {
        var color = datapoint.additionalData.color; // lighten color bars if they're child bars

        var parsedColor = color;

        if (color.includes('#')) {
          parsedColor = _colorHelper.default.hexToRgb(color);
        } // Lighten the color by 20% (child bars should be slightly lighter)


        datapoint.additionalData.color = _colorHelper.default.rgbLinearShade(0.2, parsedColor);
      }

      datapoint.additionalData.pointWidth = CHILD_HEIGHT;
      datapoint.additionalData.styled = true; // ensures all this is only run once
    }
  };

  _exports.styleChildBars = styleChildBars;
});