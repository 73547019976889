define("@huvepharma/huvepharma-aviapp-components/components/form/measurement-based-number-input", ["exports", "@ember/object", "@ember/application", "@huvepharma/huvepharma-aviapp-components/components/form/base-number-input"], function (_exports, _object, _application, _baseNumberInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * If the measurement system used is imperial, allow decimal input.
   * However, the input should be rounded to the second decimal.
   */
  var _default = _baseNumberInput.default.extend({
    min: 0,
    positive: true,
    classNameBindings: ['instantFocus:instantFocus'],
    instantFocus: false,
    didRender: function didRender() {
      if (this.instantFocus) {
        this.element.focus();
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (!this.get('measurementSystem')) {
        var application = (0, _application.getOwner)(this).lookup('application:main');
        this.set('measurementSystem', (0, _object.get)(application, 'measurementSystem'));
      }

      if ((0, _object.get)(this, 'measurementSystem') === 'imperial') {
        (0, _object.set)(this, 'enforcementType', 'decimal');
      }
    },

    /**
     * On change
     */
    update: function update(event) {
      var _this$onUpdateValue2;

      if ((0, _object.get)(this, 'allowEmpty') && event.target.value === '') {
        var _this$onUpdateValue;

        (_this$onUpdateValue = this.onUpdateValue) === null || _this$onUpdateValue === void 0 ? void 0 : _this$onUpdateValue.call(this, '');
        return;
      }

      var value = Number(event.target.value) || 0;
      (_this$onUpdateValue2 = this.onUpdateValue) === null || _this$onUpdateValue2 === void 0 ? void 0 : _this$onUpdateValue2.call(this, value);
    }
  });

  _exports.default = _default;
});