define("@huvepharma/huvepharma-aviapp-components/utils/scroll", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.scrollTo = scrollTo;
  _exports.scrollToId = scrollToId;
  _exports.isInsideViewport = isInsideViewport;

  /**
   * Scroll To an Element
   * @param {Element} element
   */
  var scrollToElement = function scrollToElement(element) {
    return element.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });
  };
  /**
   * Find the element with data-scroll-id = scrollId
   * and scroll that element into the view
   * @param {String} scrollId
   */


  function scrollTo(scrollId) {
    if (!scrollId) {
      return;
    }

    var element = document.querySelector("[data-scroll-id=\"".concat(scrollId, "\"]"));

    if (!element) {
      return;
    }

    scrollToElement(element);
  }
  /**
   * Scroll to an id
   * @param {String} id
   */


  function scrollToId(id) {
    if (!id) {
      return;
    }

    var element = document.getElementById(id);

    if (!element) {
      return;
    }

    scrollToElement(element);
  }
  /**
   * Checks to see if the given element is inside the viewport
   * @param {Element} element
   * @param {Number} bottomOffset
   * @param {Number} topOffset
   * @returns {Object}
   *    bottom: true if the bottom of the element is in the viewport, false otherwise
   *    top: true if the top of the element is in the viewport, false otherwise
   */


  function isInsideViewport(element) {
    var bottomOffset = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    var topOffset = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
    var $element = (0, _jquery.default)(element);
    var elementTop = $element.offset().top;
    var elementBottom = elementTop + $element.outerHeight();
    var viewportTop = (0, _jquery.default)(window).scrollTop();
    var viewportBottom = viewportTop + (0, _jquery.default)(window).height();
    return {
      bottom: viewportBottom + bottomOffset >= elementBottom,
      top: viewportTop + topOffset <= elementTop
    };
  }
});