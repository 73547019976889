define("@huvepharma/huvepharma-aviapp-components/templates/components/header/closeable-header", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "swl3bKMn",
    "block": "[[[41,[33,1],[[[1,\"  \"],[10,0],[14,0,\"close-container\"],[12],[1,\"\\n    \"],[10,0],[15,\"onclick\",[28,[37,2],[[30,0],[33,1]],null]],[14,0,\"close\"],[12],[1,\"\\n      \"],[10,\"svg\"],[12],[10,\"use\"],[14,\"xlink:href\",\"#close\",\"http://www.w3.org/1999/xlink\"],[12],[13],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[10,\"h1\"],[12],[1,[34,3]],[13],[1,\"\\n\"],[18,1,null]],[\"&default\"],false,[\"if\",\"onClose\",\"action\",\"title\",\"yield\"]]",
    "moduleName": "@huvepharma/huvepharma-aviapp-components/templates/components/header/closeable-header.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});