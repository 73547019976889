define("@huvepharma/huvepharma-aviapp-components/templates/components/header/actionable-label-header", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Y3xqPN/Y",
    "block": "[[[41,[48,[30,9]],[[[1,\"  \"],[18,9,null],[1,\"\\n\"]],[]],[[[1,\"  \"],[11,\"h2\"],[24,\"role\",\"button\"],[4,[38,3],[\"click\",[30,0,[\"onHeaderClick\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"onCollapse\"]],[[[1,\"      \"],[10,\"svg\"],[15,0,[29,[[52,[30,0,[\"collapsed\"]],\"arrow-button-up\",\"arrow-button-down\"]]]],[12],[1,\"\\n        \"],[10,\"use\"],[14,\"xlink:href\",\"#arrow-down\",\"http://www.w3.org/1999/xlink\"],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[1,[30,1]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]],[10,0],[14,0,\"actionable-label-header__right-part\"],[12],[1,\"\\n\"],[41,[30,2],[[[1,\"    \"],[8,[39,4],null,[[\"@label\",\"@name\",\"@checked\",\"@testId\",\"@alternativeStyle\",\"@onChecked\"],[[30,3],[30,4],[30,0,[\"initialChecked\"]],[28,[37,5],[[30,5],\"-checkbox\"],null],false,[30,0,[\"onCheckedHandler\"]]]],null],[1,\"\\n\"]],[]],null],[41,[30,6],[[[1,\"    \"],[11,\"button\"],[24,0,\"button-link\"],[24,4,\"button\"],[4,[38,3],[\"click\",[30,0,[\"onActionClick\"]]],null],[12],[1,\"\\n\"],[41,[30,7],[[[1,\"        \"],[10,\"svg\"],[14,0,\"save\"],[12],[10,\"use\"],[15,\"xlink:href\",[29,[\"#\",[30,7]]],\"http://www.w3.org/1999/xlink\"],[12],[13],[13],[1,\"\\n\"]],[]],null],[41,[30,8],[[[1,\"        \"],[10,1],[14,0,\"desc\"],[12],[1,[30,8]],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"@title\",\"@onChecked\",\"@checkboxLabel\",\"@checkboxName\",\"@testId\",\"@onAction\",\"@actionIcon\",\"@actionLabel\",\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"on\",\"form/huve-check-box\",\"concat\"]]",
    "moduleName": "@huvepharma/huvepharma-aviapp-components/templates/components/header/actionable-label-header.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});