define("@huvepharma/huvepharma-aviapp-components/templates/components/header/menu-item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "/oxC75L4",
    "block": "[[[10,3],[15,6,[29,[[52,[33,1,[\"route\"]],[28,[37,2],[[33,1,[\"route\"]]],null],\"#\"]]]],[15,\"onclick\",[36,3]],[15,\"data-test-id\",[29,[[33,1,[\"testId\"]]]]],[15,\"title\",[29,[[33,1,[\"title\"]]]]],[12],[1,\"\\n    \"],[10,\"li\"],[12],[1,\"\\n        \"],[10,\"svg\"],[14,0,\"svgSmaller\"],[12],[10,\"use\"],[15,\"xlink:href\",[29,[\"#\",[33,1,[\"icon\"]]]],\"http://www.w3.org/1999/xlink\"],[12],[13],[13],[1,\"\\n        \"],[10,2],[14,0,\"menu-item-text\"],[12],[1,\"\\n        \"],[1,[33,1,[\"label\"]]],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13]],[],false,[\"if\",\"item\",\"href-to\",\"menuItemClickedAction\"]]",
    "moduleName": "@huvepharma/huvepharma-aviapp-components/templates/components/header/menu-item.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});