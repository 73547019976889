define("@huvepharma/huvepharma-aviapp-components/templates/components/form-builder/field-label", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "fHxTF1zK",
    "block": "[[[41,[48,[30,1]],[[[1,\"  \"],[18,1,null],[1,\"\\n\"]],[]],[[[1,\"  \"],[1,[28,[35,3],[[33,4]],null]],[1,\"\\n\"]],[]]],[41,[33,5],[[[10,\"sup\"],[12],[1,\"(*)\"],[13]],[]],null]],[\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"t\",\"label\",\"required\"]]",
    "moduleName": "@huvepharma/huvepharma-aviapp-components/templates/components/form-builder/field-label.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});