define("ember-moment/utils/helper-compute", ["exports", "@ember/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(cb) {
    return function (params, hash) {
      if (!params || params && params.length === 0) {
        throw new TypeError('ember-moment: Invalid Number of arguments, expected at least 1');
      }

      var datetime = params[0];
      var allowEmpty = hash.allowEmpty || hash['allow-empty'];

      if (allowEmpty === undefined || allowEmpty === null) {
        allowEmpty = this.globalAllowEmpty;
      }

      if ((0, _utils.isBlank)(datetime)) {
        if (allowEmpty) {
          return;
        }
        /* eslint-disable no-console */


        console.warn("ember-moment: an empty value (null, undefined, or \"\") was passed to ember-moment helper");
      }

      return cb.apply(this, arguments);
    };
  }
});