define("@huvepharma/huvepharma-aviapp-components/templates/components/loadable/loadable-base", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "wIkloTbP",
    "block": "[[[41,[33,1,[\"isRunning\"]],[[[1,\"  \"],[10,1],[14,0,\"loadable-spinner-wrapper\"],[12],[1,\"\\n    \"],[10,1],[15,0,[29,[\"loadable-spinner-icon spinner-icon \",[36,2]]]],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[18,1,[\"content\"]],[1,\"\\n\"]],[]]],[1,[52,[33,4],[33,5]]],[1,\"\\n\"]],[\"&default\"],false,[\"if\",\"loadContentTask\",\"spinnerClass\",\"yield\",\"isLink\",\"text\"]]",
    "moduleName": "@huvepharma/huvepharma-aviapp-components/templates/components/loadable/loadable-base.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});