define("@huvepharma/huvepharma-aviapp-components/templates/components/form-builder/field-input", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "z9UptiSI",
    "block": "[[[6,[39,0],null,[[\"prop\",\"changeset\",\"clearable\",\"validate\",\"label\"],[[33,1],[33,2],[33,3],[33,4],[33,5]]],[[\"default\"],[[[[1,\"  \"],[18,2,[[28,[37,7],null,[[\"label\",\"input\",\"counter\",\"errors\"],[[30,1,[\"label\"]],[50,[28,[37,9],[\"form/huve-field-\",[33,10]],null],0,null,[[\"id\",\"value\",\"update\",\"placeholder\",\"class\",\"disabled\"],[[30,1,[\"formElementId\"]],[52,[33,12],[33,13],[28,[37,14],[[33,2],[33,1]],null]],[28,[37,15],[[30,0],[28,[37,16],[[30,0,[\"_update\"]],[30,1,[\"validate\"]]],null]],null],[33,17],[52,[30,1,[\"hasErrors\"]],\"error\"],[33,12]]]],[50,\"huve-character-counter\",0,null,[[\"value\"],[[28,[37,14],[[33,2],[33,1]],null]]]],[30,1,[\"errors\"]]]]]]],[1,\"\\n\"],[41,[28,[37,18],[[33,3],[28,[37,14],[[33,2],[33,1]],null]],null],[[[1,\"    \"],[11,\"svg\"],[24,0,\"field-clear\"],[4,[38,15],[[30,0],[28,[37,16],[[30,0,[\"clearValue\"]],[30,1,[\"validate\"]]],null]],null],[12],[1,\"\\n      \"],[10,\"use\"],[14,\"xlink:href\",\"#close\",\"http://www.w3.org/1999/xlink\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null]],[1]]]]]],[\"field\",\"&default\"],false,[\"form-builder/huve-field-wrapper\",\"prop\",\"changeset\",\"clearable\",\"validate\",\"label\",\"yield\",\"hash\",\"component\",\"concat\",\"inputType\",\"if\",\"disabled\",\"value\",\"get\",\"action\",\"pipe\",\"placeholder\",\"and\"]]",
    "moduleName": "@huvepharma/huvepharma-aviapp-components/templates/components/form-builder/field-input.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});