define("@huvepharma/huvepharma-aviapp-components/templates/components/charts/high-charts", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "9ixBzUMR",
    "block": "[[[18,1,[[33,1]]],[1,\"\\n\"],[10,0],[15,0,[28,[37,2],[\"chart-container \",[52,[33,4],\"d-block\",\"d-none\"]],null]],[12],[13],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"chart\",\"concat\",\"if\",\"visible\"]]",
    "moduleName": "@huvepharma/huvepharma-aviapp-components/templates/components/charts/high-charts.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});