define("@huvepharma/huvepharma-aviapp-components/templates/components/modal/modal-closeable-info", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "pR85I1NA",
    "block": "[[[6,[39,0],null,[[\"show\",\"overlayAction\",\"testId\"],[[33,1],[28,[37,2],[[30,0],\"hideModal\"],null],[33,3]]],[[\"default\"],[[[[1,\"  \"],[10,0],[14,0,\"body\"],[12],[1,\"\\n\"],[41,[33,5],[[[1,\"      \"],[10,\"img\"],[15,\"src\",[29,[[36,5]]]],[14,\"onerror\",\"this.style.display='none'\"],[12],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[10,2],[14,0,\"intro\"],[14,\"data-test-id\",\"modal-intro\"],[12],[1,[34,6]],[13],[1,\"\\n    \"],[10,2],[14,\"data-test-id\",\"modal-body\"],[12],[1,[34,7]],[13],[1,\"\\n    \"],[10,2],[14,\"data-test-id\",\"modal-footer\"],[12],[1,[34,8]],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,\"footer\"],[12],[1,\"\\n    \"],[10,2],[12],[1,\"\\n      \"],[11,\"button\"],[24,\"data-test-id\",\"close-modal\"],[4,[38,2],[[30,0],\"hideModal\"],null],[12],[1,[28,[35,9],[\"action_close\"],null]],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[],false,[\"modal/huve-base\",\"show\",\"action\",\"testId\",\"if\",\"image\",\"intro\",\"body\",\"footer\",\"t\"]]",
    "moduleName": "@huvepharma/huvepharma-aviapp-components/templates/components/modal/modal-closeable-info.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});