define("@huvepharma/huvepharma-aviapp-components/templates/components/form-builder/field-check", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "KpS5/C4r",
    "block": "[[[6,[39,0],null,[[\"prop\",\"changeset\",\"label\",\"infoLabel\",\"extraInfo\",\"validate\"],[[33,1],[33,2],[33,3],[33,4],[33,5],[33,6]]],[[\"default\"],[[[[44,[[28,[37,8],[[30,2],[28,[37,9],[[30,0],[28,[37,10],[[28,[37,11],[[33,2],[33,1]],null]],null],[28,[37,12],[[28,[37,11],[[33,2],[33,1]],null]],null]],null]],null]],[[[1,\"    \"],[18,4,[[28,[37,14],null,[[\"label\",\"infoLabel\",\"input\",\"errors\"],[[30,1,[\"label\"]],[30,1,[\"infoLabel\"]],[50,\"form/huve-check-box\",0,null,[[\"inputId\",\"name\",\"error\",\"checked\",\"onChecked\",\"disabled\",\"alternativeStyle\"],[[30,1,[\"formElementId\"]],[52,[33,17],[33,17],[28,[37,18],[\"checkbox-\",[30,1,[\"formElementId\"]]],null]],[30,1,[\"hasErrors\"]],[52,[33,19],[33,20],[28,[37,11],[[33,2],[33,1]],null]],[28,[37,9],[[30,0],[28,[37,21],[[30,3],[30,1,[\"validate\"]]],null]],null],[33,19],[33,22]]]],[30,1,[\"errors\"]]]]]]],[1,\"\\n\"]],[3]]]],[1]]]]]],[\"field\",\"@update\",\"_update\",\"&default\"],false,[\"form-builder/huve-field-wrapper\",\"prop\",\"changeset\",\"label\",\"infoLabel\",\"extraInfo\",\"validate\",\"let\",\"default-to\",\"action\",\"mut\",\"get\",\"not\",\"yield\",\"hash\",\"component\",\"if\",\"name\",\"concat\",\"disabled\",\"value\",\"pipe\",\"alternativeStyle\"]]",
    "moduleName": "@huvepharma/huvepharma-aviapp-components/templates/components/form-builder/field-check.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});