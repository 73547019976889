define("@huvepharma/huvepharma-aviapp-components/components/scoring/scoring-toggle-input", ["exports", "@ember/component", "@ember/object", "@huvepharma/huvepharma-aviapp-components/templates/components/scoring/scoring-toggle-input"], function (_exports, _component, _object, _scoringToggleInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }

  var _default = _component.default.extend({
    layout: _scoringToggleInput.default,
    classNames: ['scoring-toggle-input'],
    scores: (0, _object.computed)('values', function () {
      // Using a Set here to ensure unique values
      return _toConsumableArray(new Set(this.get('values').filter(function (v) {
        return typeof v === 'string';
      }).map(function (v) {
        return v.charAt(0);
      })));
    }),
    comparableCurrentScore: (0, _object.computed)('currentScore', function () {
      var currentScore = this.get('currentScore');

      if (typeof currentScore === 'string') {
        return currentScore.charAt(0);
      }

      return currentScore;
    }),
    actions: {
      toggleScore: function toggleScore(score) {
        // map score to one of the values
        var toggledValue = this.get('values').find(function (value) {
          return typeof value === 'string' && value.charAt(0) === score;
        });

        if (this.get('toggleScore')) {
          this.toggleScore(toggledValue);
        } else {
          this.set('currentScore', toggledValue);
        }
      },
      showInfo: function showInfo() {
        if (!this.get('hideInfo')) {
          this.clickInfoAction();
        }
      }
    }
  });

  _exports.default = _default;
});