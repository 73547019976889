define("ember-validators/collection", ["exports", "@ember/debug", "@ember/array", "@ember/utils", "ember-validators/utils/validation-error"], function (_exports, _debug, _array, _utils, _validationError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateCollection;

  /**
   *  @class Collection
   *  @module Validators
   */

  /**
   * @method validate
   * @param {Any} value
   * @param {Object} options
   * @param {Boolean} options.collection
   * @param {Object} model
   * @param {String} attribute
   */
  function validateCollection(value, options, model, attribute) {
    var collection = options.collection;
    (false && !((0, _utils.isPresent)(collection)) && (0, _debug.assert)("[validator:collection] [".concat(attribute, "] option 'collection' is required"), (0, _utils.isPresent)(collection)));

    if (collection === true && !(0, _array.isArray)(value)) {
      return (0, _validationError.default)('collection', value, options);
    }

    if (collection === false && (0, _array.isArray)(value)) {
      return (0, _validationError.default)('singular', value, options);
    }

    return true;
  }
});