define("@huvepharma/huvepharma-aviapp-components/templates/components/form-builder/base-form", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "op6CpH5o",
    "block": "[[[18,1,[[28,[37,1],null,[[\"field-input\",\"field-autocomplete\",\"field-number-step\",\"field-textarea\",\"field-select\",\"field-check\",\"field-custom-selector\",\"field-button-group\",\"field-range-slider\"],[[50,\"form-builder/huve-field-input\",0,null,[[\"changeset\",\"validate\"],[[33,3],[33,4]]]],[50,\"form-builder/huve-field-input\",0,null,[[\"changeset\",\"validate\",\"inputType\"],[[33,3],[33,4],\"autocomplete\"]]],[50,\"form-builder/huve-field-number-step\",0,null,[[\"validate\",\"changeset\"],[[33,4],[33,3]]]],[50,\"form-builder/huve-field-textarea\",0,null,[[\"validate\",\"changeset\"],[[33,4],[33,3]]]],[50,\"form-builder/huve-field-select\",0,null,[[\"validate\",\"changeset\"],[[33,4],[33,3]]]],[50,\"form-builder/huve-field-check\",0,null,[[\"validate\",\"changeset\"],[[33,4],[33,3]]]],[50,\"form-builder/huve-field-custom-selector\",0,null,[[\"validate\",\"changeset\"],[[33,4],[33,3]]]],[50,\"form-builder/huve-field-button-group\",0,null,[[\"validate\",\"changeset\"],[[33,4],[33,3]]]],[50,\"form-builder/huve-field-range-slider\",0,null,[[\"validate\",\"changeset\"],[[33,4],[33,3]]]]]]],[33,3]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"component\",\"changeset\",\"validate\"]]",
    "moduleName": "@huvepharma/huvepharma-aviapp-components/templates/components/form-builder/base-form.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});