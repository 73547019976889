define("@huvepharma/huvepharma-aviapp-components/templates/components/tree/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Sva4Jstj",
    "block": "[[[41,[48,[30,2]],[[[6,[39,2],null,[[\"ignoreRoot\",\"labelKey\",\"data\",\"tree\",\"onHandleClick\"],[[33,3],[33,4],[33,5,[\"data\"]],[33,5],[28,[37,6],[[30,0],\"onHandleClick\"],null]]],[[\"default\"],[[[[1,\"    \"],[18,2,[[30,1]]],[1,\"\\n\"]],[1]]]]]],[]],[[[1,\"  \"],[1,[28,[35,2],null,[[\"ignoreRoot\",\"labelKey\",\"data\",\"tree\",\"onHandleClick\"],[[33,3],[33,4],[33,5,[\"data\"]],[33,5],[28,[37,6],[[30,0],\"onHandleClick\"],null]]]]],[1,\"\\n\"]],[]]]],[\"node\",\"&default\"],false,[\"if\",\"has-block\",\"tree/huve-node\",\"ignoreRoot\",\"labelKey\",\"tree\",\"action\",\"yield\"]]",
    "moduleName": "@huvepharma/huvepharma-aviapp-components/templates/components/tree/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});