define("@huvepharma/huvepharma-aviapp-components/constants/chart-options", ["exports", "@huvepharma/huvepharma-aviapp-components/constants/chart-styles"], function (_exports, _chartStyles) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.LINE_CHART_OPTIONS = _exports.SUMMARY_CHART_OPTIONS = _exports.COLUMN_CHART_OPTIONS = _exports.STACKED_BAR_CHART_OPTIONS = _exports.BASE_CHART_OPTIONS = void 0;
  var BASE_CHART_OPTIONS = {
    chart: {
      alignTicks: false,
      backgroundColor: _chartStyles.default.backgroundColor
    },
    exporting: {
      buttons: {
        contextButton: {
          enabled: false
        }
      }
    },
    legend: {
      enabled: false,
      itemStyle: _chartStyles.default.legendStyle
    },
    plotOptions: {
      tooltip: {}
    },
    tooltip: {
      backgroundColor: null,
      borderWidth: 0,
      shadow: false,
      useHTML: true
    },
    title: {
      text: null
    },
    xAxis: {
      labels: {
        style: _chartStyles.default.darkLabelStyle
      },
      lineColor: _chartStyles.default.axisLineStyle.lineColor,
      lineWidth: _chartStyles.default.axisLineStyle.lineWidth,
      title: {
        style: _chartStyles.default.axisTitleStyle
      }
    }
  };
  _exports.BASE_CHART_OPTIONS = BASE_CHART_OPTIONS;
  var STACKED_BAR_CHART_OPTIONS = {
    chart: {
      type: 'bar'
    },
    plotOptions: {
      bar: {
        pointWidth: _chartStyles.default.bar.height,
        borderWidth: 0,
        borderColor: _chartStyles.default.colours.borderColor,
        // labels on top of bars
        dataLabels: {
          color: _chartStyles.default.colours.darkLabelColor,
          enabled: true,
          style: _chartStyles.default.dataLabelStyle
        },
        tooltip: {
          headerFormat: '',
          valueSuffix: '%'
        }
      },
      scatter: {
        marker: {
          fillColor: _chartStyles.default.darkLabelStyle.color,
          radius: 6
        },
        tooltip: {
          headerFormat: '',
          pointFormat: 'Av. score: {point.y}'
        }
      },
      // enabled stacked bars
      series: {
        stacking: 'normal'
      }
    },
    yAxis: [{
      // primary axis
      labels: {
        style: _chartStyles.default.lightLabelStyle
      },
      lineColor: _chartStyles.default.axisLineStyle.lineColor,
      lineWidth: _chartStyles.default.axisLineStyle.lineWidth,
      min: 0,
      max: 100,
      showRects: false,
      // total percentage labels at end of bars
      stackLabels: {
        enabled: true,
        textAlign: 'left',
        style: {
          fontSize: '14px',
          color: _chartStyles.default.colours.darkLabelColor
        },
        x: 15
      },
      title: {
        align: 'high',
        style: _chartStyles.default.titleStyle,
        text: '%',
        y: 5
      },
      reversedStacks: false
    }, {
      // secondary axis
      gridLineWidth: 0,
      labels: {
        style: _chartStyles.default.lightLabelStyle
      },
      lineColor: _chartStyles.default.axisLineStyle.lineColor,
      lineWidth: _chartStyles.default.axisLineStyle.lineWidth,
      min: 0,
      max: 4,
      opposite: true,
      showEmpty: false,
      showRects: false,
      title: {
        align: 'high',
        style: _chartStyles.default.titleStyle,
        text: 'Av. score',
        y: -15
      }
    }]
  };
  _exports.STACKED_BAR_CHART_OPTIONS = STACKED_BAR_CHART_OPTIONS;
  var COLUMN_CHART_OPTIONS = {
    chart: {
      marginLeft: _chartStyles.default.margins.left,
      marginRight: _chartStyles.default.margins.right,
      marginTop: _chartStyles.default.margins.top,
      type: 'column',
      height: 350
    },
    plotOptions: {
      column: {
        tooltip: {
          headerFormat: ''
        },
        minPointLength: 2
      }
    },
    yAxis: {
      labels: {
        style: _chartStyles.default.lightLabelStyle
      },
      lineColor: _chartStyles.default.axisLineStyle.lineColor,
      lineWidth: _chartStyles.default.axisLineStyle.lineWidth,
      showRects: false,
      title: _chartStyles.default.leftYAxisTitleStyle
    }
  };
  _exports.COLUMN_CHART_OPTIONS = COLUMN_CHART_OPTIONS;
  var SUMMARY_CHART_OPTIONS = {
    chart: {
      type: 'bar'
    },
    plotOptions: {
      bar: {
        borderWidth: 0,
        tooltip: {
          headerFormat: ''
        }
      },
      scatter: {
        marker: {
          fillColor: _chartStyles.default.darkLabelStyle.color,
          radius: 6
        },
        tooltip: {
          headerFormat: '',
          pointFormat: 'Score: {point.y}'
        }
      },
      // enabled stacked bars
      series: {
        stacking: 'normal'
      }
    },
    yAxis: [{
      // primary axis
      labels: {
        style: _chartStyles.default.lightLabelStyle
      },
      lineColor: _chartStyles.default.axisLineStyle.lineColor,
      lineWidth: _chartStyles.default.axisLineStyle.lineWidth,
      min: 0,
      max: 4,
      showRects: false,
      title: {
        align: 'high',
        style: _chartStyles.default.titleStyle,
        text: 'Score',
        y: 5
      }
    }, {
      // secondary axis
      gridLineWidth: 0,
      labels: {
        style: _chartStyles.default.lightLabelStyle
      },
      lineColor: _chartStyles.default.axisLineStyle.lineColor,
      lineWidth: _chartStyles.default.axisLineStyle.lineWidth,
      min: 0,
      max: 4,
      opposite: true,
      showEmpty: false,
      showRects: false,
      title: {
        align: 'high',
        style: _chartStyles.default.titleStyle,
        text: 'Score',
        y: -15
      }
    }]
  };
  _exports.SUMMARY_CHART_OPTIONS = SUMMARY_CHART_OPTIONS;
  var LINE_CHART_OPTIONS = {
    colors: ['#7CB5EC', '#57585D', '#90ED7D', '#F7A35C', '#8085E9', '#C3C327'],
    chart: {
      marginLeft: _chartStyles.default.margins.left,
      marginRight: _chartStyles.default.margins.right,
      marginTop: _chartStyles.default.margins.top,
      type: 'line'
    },
    plotOptions: {
      line: {
        marker: {
          enabled: true
        },
        tooltip: {
          headerFormat: ''
        }
      },
      series: {
        connectNulls: true
      }
    },
    xAxis: {
      title: {
        align: 'high',
        offset: 0,
        x: 40,
        y: -7
      }
    },
    yAxis: {
      labels: {
        style: _chartStyles.default.lightLabelStyle
      },
      lineColor: _chartStyles.default.axisLineStyle.lineColor,
      lineWidth: _chartStyles.default.axisLineStyle.lineWidth,
      showRects: false,
      title: _chartStyles.default.leftYAxisTitleStyle,
      min: 0
    }
  };
  _exports.LINE_CHART_OPTIONS = LINE_CHART_OPTIONS;
});