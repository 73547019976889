define("@huvepharma/huvepharma-aviapp-components/constants/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.UNKNOWN = void 0;
  // UNKNOWN region value
  var UNKNOWN = 'UNKNOWN';
  _exports.UNKNOWN = UNKNOWN;
});