define("@huvepharma/huvepharma-aviapp-components/templates/components/sidebar/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "kSDBgNDj",
    "block": "[[[18,1,[[28,[37,1],null,[[\"header\",\"content\",\"footer\"],[[50,\"sidebar/huve-header\",0,null,[[\"onClose\"],[[33,3]]]],[50,\"loadable/huve-content\",0,null,[[\"isLoading\"],[[33,4]]]],[50,\"sidebar/huve-footer\",0,null,null]]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"component\",\"onClose\",\"isLoading\"]]",
    "moduleName": "@huvepharma/huvepharma-aviapp-components/templates/components/sidebar/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});