define("@huvepharma/huvepharma-aviapp-components/templates/components/form-builder/field-select", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "G47EgImO",
    "block": "[[[6,[39,0],null,[[\"prop\",\"changeset\",\"label\",\"validate\"],[[33,1],[33,2],[33,3],[33,4]]],[[\"default\"],[[[[44,[[28,[37,6],[[30,2],[28,[37,7],[[30,0],[28,[37,8],[[28,[37,9],[[33,2],[33,1]],null]],null]],null]],null]],[[[1,\"    \"],[18,4,[[28,[37,11],null,[[\"label\",\"input\",\"errors\"],[[30,1,[\"label\"]],[50,\"form/huve-select-box\",0,null,[[\"class\",\"selectedValue\",\"action\"],[[28,[37,13],[\"field-select\",[52,[30,1,[\"hasErrors\"]],\" error\"]],null],[28,[37,9],[[33,2],[33,1]],null],[28,[37,7],[[30,0],[28,[37,15],[[30,3],[30,1,[\"validate\"]]],null]],null]]]],[30,1,[\"errors\"]]]]]]],[1,\"\\n\"]],[3]]]],[1]]]]]],[\"field\",\"@update\",\"_update\",\"&default\"],false,[\"form-builder/huve-field-wrapper\",\"prop\",\"changeset\",\"label\",\"validate\",\"let\",\"default-to\",\"action\",\"mut\",\"get\",\"yield\",\"hash\",\"component\",\"concat\",\"if\",\"pipe\"]]",
    "moduleName": "@huvepharma/huvepharma-aviapp-components/templates/components/form-builder/field-select.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});