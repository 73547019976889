define("@huvepharma/huvepharma-aviapp-components/templates/components/buttons/button-play", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "PXZAGqtV",
    "block": "[[[6,[39,0],null,[[\"class\",\"onClick\"],[[28,[37,1],[\"play-button \",[33,2]],null],[28,[37,3],[[30,0],[28,[37,4],[[33,5]],null]],null]]],[[\"default\"],[[[[1,\"  \"],[10,1],[14,0,\"play-shape\"],[12],[13],[1,\"\\n  \"],[18,1,null],[1,\"\\n\"]],[]]]]]],[\"&default\"],false,[\"buttons/huve-base\",\"concat\",\"class\",\"action\",\"optional\",\"onClick\",\"yield\"]]",
    "moduleName": "@huvepharma/huvepharma-aviapp-components/templates/components/buttons/button-play.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});