define("@huvepharma/huvepharma-aviapp-components/components/links/link-external", ["exports", "@ember/template-factory", "@ember/component", "@ember/object"], function (_exports, _templateFactory, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: 'a',
    attributeBindings: ['href', 'target'],
    target: '_blank',
    layout: (0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if (and imgSrc imgAlt) }}
          <img src={{imgSrc}} alt={{imgAlt}}/>
        {{else}}
          {{text}}
        {{/if}}
      
    */
    {
      "id": "TI7Ow7Ww",
      "block": "[[[1,\"\\n\"],[41,[28,[37,1],[[33,2],[33,3]],null],[[[1,\"      \"],[10,\"img\"],[15,\"src\",[36,2]],[15,\"alt\",[36,3]],[12],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[34,4]],[1,\"\\n\"]],[]]],[1,\"  \"]],[],false,[\"if\",\"and\",\"imgSrc\",\"imgAlt\",\"text\"]]",
      "moduleName": "(unknown template module)",
      "isStrictMode": false
    }),
    click: function click(e) {
      // If we're using the Xamarin app, we do not
      // want to open the link in the current webview,
      // but rather open it outside of the app, so we
      // prevent the default functionality of the anchor tag.
      if ((0, _object.get)(this, 'preventDefault')) {
        e.preventDefault();
      }

      if ((0, _object.get)(this, 'openLink') && typeof (0, _object.get)(this, 'openLink') === 'function') {
        (0, _object.get)(this, 'openLink')((0, _object.get)(this, 'href'), (0, _object.get)(this, 'label'));
      }
    }
  });

  _exports.default = _default;
});