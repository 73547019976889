define("@huvepharma/huvepharma-aviapp-components/components/scoring/scoring-number-input", ["exports", "@ember/object", "@ember/object/computed", "@huvepharma/huvepharma-aviapp-components/components/scoring/scoring-toggle-input", "@huvepharma/huvepharma-aviapp-components/templates/components/scoring/scoring-toggle-input"], function (_exports, _object, _computed, _scoringToggleInput, _scoringToggleInput2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }

  var _default = _scoringToggleInput.default.extend({
    layout: _scoringToggleInput2.default,
    currentScore: 0,
    min: 0,
    // Stripped down version of parent computed prop for increased performance.
    comparableCurrentScore: (0, _computed.alias)('currentScore'),

    /**
     * Returns an array of consecutive integers starting at min value and ending at
     * the max value, as long as that value is below or equal to 10 and a valid
     * integer.
     */
    scores: (0, _object.computed)('max', 'min', function () {
      var min = this.get('min');
      var max = this.get('max') ? this.get('max') : 1;
      var times = parseInt(max - min, 10) + 1;

      if (times && times > 0 && times < 10) {
        return _toConsumableArray(Array(times).keys()).map(function (x) {
          return x + min;
        });
      }

      return [0, 1];
    }),
    actions: {
      /**
       * Simpler implementation then the one in parent, since the input can only be numeric values.
       * @param {Number} score
       */
      toggleScore: function toggleScore(score) {
        if (this.get('toggleScore')) {
          this.toggleScore(score);
        } else {
          this.set('currentScore', score);
        }
      }
    }
  });

  _exports.default = _default;
});