define("@huvepharma/huvepharma-aviapp-components/services/session", ["exports", "@ember/service"], function (_exports, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Empty service, should be overwritten in implementation.
  // This exists so a session service can be injected in the regions service.
  // I could not simply remove this dependency, because it would mean removing
  // a bunch of valuable unit tests.
  var _default = _service.default.extend();

  _exports.default = _default;
});