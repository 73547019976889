define("@huvepharma/huvepharma-aviapp-components/helpers/is-active", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isActive = isActive;
  _exports.default = void 0;

  /**
   * Computes if the top level of the current route is in a given array of
   * top level routes and returns the string 'active' if so.
   */
  function isActive(args) {
    var result = false;
    if (typeof args[0] !== 'string' || typeof args[1] !== 'string') return result;
    var currentPathTopLevel = args[0].split('.')[0];
    var activeFor = args[1].split(' ');

    for (var i = 0; i < activeFor.length; i++) {
      if (activeFor[i] === currentPathTopLevel) {
        result = true;
        break;
      }
    }

    return result;
  }

  var _default = (0, _helper.helper)(isActive);

  _exports.default = _default;
});