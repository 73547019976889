define("ember-highcharts/utils/build-options", ["exports", "@ember/polyfills", "deepmerge"], function (_exports, _polyfills, _deepmerge) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = buildOptions;
  _exports.EMPTY_CHART_CONTENT = void 0;
  var EMPTY_CHART_CONTENT = [Object.freeze({
    id: 'noData',
    data: 0,
    color: '#aaaaaa'
  })];
  _exports.EMPTY_CHART_CONTENT = EMPTY_CHART_CONTENT;

  function buildOptions(theme) {
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var content = arguments.length > 2 ? arguments[2] : undefined;
    // if 'no-data-to-display' module has been imported, keep empty series
    // and leave it to highcharts to show no data label.
    var isEmpty = !Highcharts.Chart.prototype.showNoData && !(content !== null && content !== void 0 && content.length);
    var chartOptions = (0, _deepmerge.default)(theme, options);
    var defaults = {
      series: isEmpty ? EMPTY_CHART_CONTENT : content
    };
    return (0, _polyfills.assign)(defaults, chartOptions);
  }
});