define("@huvepharma/huvepharma-aviapp-components/utils/color-helper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  /* eslint-disable */

  /*
   * Convert a Hex value to an rgb value
   *
   * Found at https://stackoverflow.com/a/5624139
   */
  var hexToRgb = function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    var r = parseInt(result[1], 16);
    var g = parseInt(result[2], 16);
    var b = parseInt(result[3], 16);
    return result ? "rgb(".concat(r, ",").concat(g, ",").concat(b, ")") : null;
  };
  /**
   * Lighter or darken an rgb color
   *
   * Found in an answer to: https://stackoverflow.com/questions/5560248/programmatically-lighten-or-darken-a-hex-color-or-rgb-and-blend-colors
   *
   * It's not pretty code, but it runs fast and works.
   *
   * @param p: Percentage value, from -1 to 1. Negative values return a lighter color, positive values a
   * 	darker color. So calling this function with -0.1 will return a new rgb color which is 10% lighter then
   *  the input color.
   * @param c: Color string. Expects an rgb or rgba value, though we currently only use it for rgb values.
   */


  var rgbLinearShade = function rgbLinearShade(p, c) {
    var i = parseInt,
        r = Math.round,
        _c$split = c.split(','),
        _c$split2 = _slicedToArray(_c$split, 4),
        a = _c$split2[0],
        b = _c$split2[1],
        c = _c$split2[2],
        d = _c$split2[3],
        P = p < 0,
        t = P ? 0 : 255 * p,
        P = P ? 1 + p : 1 - p;

    return 'rgb' + (d ? 'a(' : '(') + r(i(a[3] == 'a' ? a.slice(5) : a.slice(4)) * P + t) + ',' + r(i(b) * P + t) + ',' + r(i(c) * P + t) + (d ? ',' + d : ')');
  };

  var _default = {
    hexToRgb: hexToRgb,
    rgbLinearShade: rgbLinearShade
  };
  _exports.default = _default;
});