define("@huvepharma/huvepharma-aviapp-components/components/header/header-icon-button", ["exports", "@ember/object/computed", "@ember/component", "@huvepharma/huvepharma-aviapp-components/templates/components/header/header-icon-button"], function (_exports, _computed, _component, _headerIconButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    layout: _headerIconButton.default,
    tagName: 'button',
    classNames: ['header-icon-button'],
    classNameBindings: ['isLoading:loading', 'label:header-icon-button-with-label'],
    attributeBindings: ['disabledState:disabled', 'testId:data-test-id'],
    isLoading: false,
    isDisabled: false,
    noLoading: false,
    overrideDisable: false,
    disabledState: (0, _computed.or)('overrideDisable', 'isDisabled'),

    /**
     * Initialisation
     */
    init: function init() {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      this._super(args);

      this.set('isLoading', false);
    },

    /**
     * Click action
     */
    click: function click(e) {
      var _this = this;

      e.preventDefault();

      if (!this.get('noLoading')) {
        this.set('isLoading', true);
        this.set('isDisabled', true);
      }

      var param = this.get('param');

      if (this.get('action')) {
        var promise;

        if (param) {
          promise = this.get('action')(param);
        } else {
          promise = this.get('action')();
        }

        if (promise) {
          promise.finally(function () {
            if (!_this.get('isDestroyed')) {
              _this.set('isLoading', false);

              _this.set('isDisabled', false);
            }
          });
        }
      }
    }
  });

  _exports.default = _default;
});