define("ember-truth-helpers/helpers/not", ["exports", "@ember/component/helper", "ember-truth-helpers/utils/truth-convert"], function (_exports, _helper, _truthConvert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.not = not;
  _exports.default = void 0;

  function not(params) {
    for (var i = 0, len = params.length; i < len; i++) {
      if ((0, _truthConvert.default)(params[i]) === true) {
        return false;
      }
    }

    return true;
  }

  var _default = (0, _helper.helper)(not);

  _exports.default = _default;
});