define("@huvepharma/huvepharma-aviapp-components/components/form-builder/field-check", ["exports", "@huvepharma/huvepharma-aviapp-components/components/form-builder/field-base", "@huvepharma/huvepharma-aviapp-components/templates/components/form-builder/field-check"], function (_exports, _fieldBase, _fieldCheck) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fieldBase.default.extend({
    layout: _fieldCheck.default
  });

  _exports.default = _default;
});