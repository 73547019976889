define("@huvepharma/huvepharma-aviapp-components/templates/components/overlay/content", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "jczjruK8",
    "block": "[[[18,1,[[28,[37,1],null,[[\"header\",\"content\"],[[50,\"header/huve-closeable\",0,null,[[\"onClose\"],[[33,3]]]],[50,\"loadable/huve-content\",0,null,[[\"isLoading\"],[[33,4]]]]]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"component\",\"onClose\",\"isLoading\"]]",
    "moduleName": "@huvepharma/huvepharma-aviapp-components/templates/components/overlay/content.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});