define("@huvepharma/huvepharma-aviapp-components/templates/components/form/check-box", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "0eWb/KJa",
    "block": "[[[10,0],[14,0,\"check-box-container\"],[12],[1,\"\\n  \"],[8,[39,0],[[16,1,[52,[30,1],[30,1],[28,[37,2],[\"checkbox-\",[30,2]],null]]],[16,\"data-test-id\",[30,3]],[16,3,[30,2]],[4,[38,3],[\"click\",[30,0,[\"onClick\"]]],null]],[[\"@type\",\"@checked\"],[\"checkbox\",[30,0,[\"checked\"]]]],null],[1,\"\\n\\n  \"],[10,\"svg\"],[15,0,[29,[\"state state-check \",[52,[30,4],\"error\"],\" \",[52,[30,0,[\"indeterminate\"]],\"indeterminate\"]]]],[12],[1,\"\\n\"],[41,[30,0,[\"indeterminate\"]],[[[1,\"      \"],[10,\"use\"],[14,\"xlink:href\",\"#indeterminate\",\"http://www.w3.org/1999/xlink\"],[12],[13],[1,\"\\n\"]],[]],[[[41,[30,0,[\"checked\"]],[[[1,\"      \"],[10,\"use\"],[14,\"xlink:href\",\"#check\",\"http://www.w3.org/1999/xlink\"],[12],[13],[1,\"\\n    \"]],[]],null]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"check-box-label-container\"],[12],[1,\"\\n\"],[41,[48,[30,7]],[[[1,\"    \"],[18,7,null],[1,\"\\n\"]],[]],[[[41,[30,5],[[[1,\"      \"],[10,\"label\"],[15,\"for\",[29,[\"checkbox-\",[30,2]]]],[12],[1,\"\\n        \"],[1,[30,5]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,6],[[[1,\"      \"],[10,\"label\"],[14,0,\"extra-info\"],[15,\"for\",[29,[\"checkbox-\",[30,2]]]],[12],[1,\"\\n        \"],[1,[30,6]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null]],[]]],[13],[1,\"\\n\"]],[\"@inputId\",\"@name\",\"@testId\",\"@error\",\"@label\",\"@infoLabel\",\"&default\"],false,[\"input\",\"if\",\"concat\",\"on\",\"has-block\",\"yield\"]]",
    "moduleName": "@huvepharma/huvepharma-aviapp-components/templates/components/form/check-box.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});