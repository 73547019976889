define("@huvepharma/huvepharma-aviapp-components/templates/components/form/field-input-mask", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "qjejkhEH",
    "block": "[[[1,[28,[35,0],null,[[\"mask\",\"options\",\"value\",\"update\",\"class\",\"disabled\"],[[33,1],[33,2],[33,3],[33,4],[33,5],[33,6]]]]]],[],false,[\"one-way-input-mask\",\"mask\",\"options\",\"value\",\"update\",\"class\",\"disabled\"]]",
    "moduleName": "@huvepharma/huvepharma-aviapp-components/templates/components/form/field-input-mask.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});