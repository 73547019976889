define("@huvepharma/huvepharma-aviapp-components/components/header/company-switch", ["exports", "@ember/component", "@huvepharma/huvepharma-aviapp-components/templates/components/header/company-switch"], function (_exports, _component, _companySwitch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // @ts-ignore: Ignore import of compiled template
  var _default = _component.default.extend({
    layout: _companySwitch.default,
    actions: {
      switchCompany: function switchCompany(company) {
        this.switchCompany(company);
      },
      toggleCompanySelector: function toggleCompanySelector() {
        if (this.onToggleCompanySelector) {
          this.onToggleCompanySelector();
        }
      }
    }
  });

  _exports.default = _default;
});