define("@huvepharma/huvepharma-aviapp-components/components/buttons/button-play", ["exports", "@huvepharma/huvepharma-aviapp-components/components/buttons/button-base", "@huvepharma/huvepharma-aviapp-components/templates/components/buttons/button-play"], function (_exports, _buttonBase, _buttonPlay) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _buttonBase.default.extend({
    layout: _buttonPlay.default
  });

  _exports.default = _default;
});