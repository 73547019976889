define("@huvepharma/huvepharma-aviapp-components/components/programs/program-item", ["exports", "@ember/component", "@huvepharma/huvepharma-aviapp-components/templates/components/programs/program-item"], function (_exports, _component, _programItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    dateFormat: 'DD/MM/YYYY',
    layout: _programItem.default,
    actions: {
      onClick: function onClick() {
        var _this$action;

        (_this$action = this.action) === null || _this$action === void 0 ? void 0 : _this$action.call(this);
      }
    }
  });

  _exports.default = _default;
});