define("@huvepharma/huvepharma-aviapp-components/components/tables/gfr-table-header", ["exports", "@ember/component", "@huvepharma/huvepharma-aviapp-components/templates/components/tables/gfr-table-header"], function (_exports, _component, _gfrTableHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    layout: _gfrTableHeader.default,
    rootTestId: 'table-header'
  });

  _exports.default = _default;
});