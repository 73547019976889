define("@huvepharma/huvepharma-aviapp-components/components/modal/date-picker", ["exports", "@ember/component", "@ember/object", "@huvepharma/huvepharma-aviapp-components/templates/components/modal/date-picker"], function (_exports, _component, _object, _datePicker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    layout: _datePicker.default,
    shouldChangeDateOnClick: true,
    actions: {
      changeCurrentSelectedDate: function changeCurrentSelectedDate(date) {
        var _this$changeDate;

        (_this$changeDate = this.changeDate) === null || _this$changeDate === void 0 ? void 0 : _this$changeDate.call(this, date);

        if (this.shouldChangeDateOnClick) {
          (0, _object.set)(this, 'selectedDate', date);
        }
      },
      closeAction: function closeAction() {
        var _this$changeDate2, _this$closeAction;

        (_this$changeDate2 = this.changeDate) === null || _this$changeDate2 === void 0 ? void 0 : _this$changeDate2.call(this, this.selectedDate);
        (_this$closeAction = this.closeAction) === null || _this$closeAction === void 0 ? void 0 : _this$closeAction.call(this);
        this.set('show', false);
      }
    }
  });

  _exports.default = _default;
});