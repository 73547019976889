define("@huvepharma/huvepharma-aviapp-components/components/modal/modal-closeable-info", ["exports", "@ember/component", "@huvepharma/huvepharma-aviapp-components/templates/components/modal/modal-closeable-info"], function (_exports, _component, _modalCloseableInfo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    layout: _modalCloseableInfo.default,
    tagName: '',
    actions: {
      /**
       * Hide the modal
       */
      hideModal: function hideModal() {
        this.set('show', false);
      }
    }
  });

  _exports.default = _default;
});