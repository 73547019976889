define("@huvepharma/huvepharma-aviapp-components/templates/components/form-builder/field-textarea", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "xzUIK3zy",
    "block": "[[[6,[39,0],null,[[\"prop\",\"changeset\",\"validate\",\"label\"],[[33,1],[33,2],[33,3],[33,4]]],[[\"default\"],[[[[1,\"  \"],[18,2,[[28,[37,6],null,[[\"label\",\"input\",\"counter\",\"errors\"],[[30,1,[\"label\"]],[50,\"form/huve-field-textarea\",0,null,[[\"value\",\"update\",\"placeholder\",\"class\",\"disabled\"],[[52,[33,9],[33,10],[28,[37,11],[[33,2],[33,1]],null]],[28,[37,12],[[30,0],[28,[37,13],[[30,0,[\"_update\"]],[30,1,[\"validate\"]]],null]],null],[33,14],[52,[30,1,[\"hasErrors\"]],\"error\"],[33,9]]]],[50,\"huve-character-counter\",0,null,[[\"value\"],[[28,[37,11],[[33,2],[33,1]],null]]]],[30,1,[\"errors\"]]]]]]],[1,\"\\n\"]],[1]]]]]],[\"field\",\"&default\"],false,[\"form-builder/huve-field-wrapper\",\"prop\",\"changeset\",\"validate\",\"label\",\"yield\",\"hash\",\"component\",\"if\",\"disabled\",\"value\",\"get\",\"action\",\"pipe\",\"placeholder\"]]",
    "moduleName": "@huvepharma/huvepharma-aviapp-components/templates/components/form-builder/field-textarea.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});