define("@huvepharma/huvepharma-aviapp-components/templates/components/buttons/add-button", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "OnBZ9MrU",
    "block": "[[[10,3],[15,6,[36,0]],[15,0,[29,[\"button add-button action \",[52,[33,2],[28,[37,3],[\"button-\",[33,2]],null]],\" \",[52,[33,4],\"button-large\"]]]],[15,\"data-test-id\",[36,5]],[12],[1,\"\\n  \"],[10,1],[12],[1,\"\\n    \"],[10,\"svg\"],[12],[10,\"use\"],[14,\"xlink:href\",\"#add-outline\",\"http://www.w3.org/1999/xlink\"],[12],[13],[13],[1,\"\\n    \"],[1,[34,6]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"link\",\"if\",\"color\",\"concat\",\"isLarge\",\"dataTestId\",\"label\"]]",
    "moduleName": "@huvepharma/huvepharma-aviapp-components/templates/components/buttons/add-button.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});