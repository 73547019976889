define("@huvepharma/huvepharma-aviapp-components/components/buttons/button-base", ["exports", "@ember/component", "@huvepharma/huvepharma-aviapp-components/templates/components/buttons/button-base"], function (_exports, _component, _buttonBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    layout: _buttonBase.default
  });

  _exports.default = _default;
});