define("ember-screen/index", ["exports", "ember-screen/services/screen", "ember-screen/breakpoint"], function (_exports, _screen, _breakpoint) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "breakpoint", {
    enumerable: true,
    get: function get() {
      return _breakpoint.default;
    }
  });
  _exports.default = void 0;
  var _default = _screen.default;
  _exports.default = _default;
});