define("@huvepharma/huvepharma-aviapp-components/components/form/validated-input", ["exports", "@ember/component", "@ember/object", "@ember/string", "@ember/debug", "@huvepharma/huvepharma-aviapp-components/templates/components/form/validated-input"], function (_exports, _component, _object, _string, _debug, _validatedInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var LINKED_STRING_OPEN_DELIMITER = '[';
  var LINKED_STRING_CLOSE_DELIMITER = ']';

  var _default = _component.default.extend({
    layout: _validatedInput.default,
    classNameBindings: ['sectionClass'],
    tagName: 'section',
    interpolatedLinkString: (0, _object.computed)('linkLabel', 'file', function () {
      var baseString = (0, _object.get)(this, 'linkLabel.string') ? (0, _object.get)(this, 'linkLabel.string') // instance of Ember SafeString
      : (0, _object.get)(this, 'linkLabel'); // Regular string

      var file = (0, _object.get)(this, 'file');
      var result = baseString;
      var linkedPart = '';

      if (baseString.includes(LINKED_STRING_OPEN_DELIMITER) && baseString.includes(LINKED_STRING_CLOSE_DELIMITER)) {
        linkedPart = baseString.slice(baseString.indexOf(LINKED_STRING_OPEN_DELIMITER) + 1, baseString.lastIndexOf(LINKED_STRING_CLOSE_DELIMITER));
        result = result.replace(LINKED_STRING_OPEN_DELIMITER, '').replace(LINKED_STRING_CLOSE_DELIMITER, '');
      }

      if (file && linkedPart) {
        result = result.replace(linkedPart, "<a href=\"/assets/".concat(file, ".pdf\" target=\"_blank\">").concat(linkedPart, "</a>"));
      }

      return (0, _string.htmlSafe)(result);
    }),
    init: function init() {
      this._super.apply(this, arguments);

      (false && !(false) && (0, _debug.deprecate)('The validated input is deprecated. Try to use and create form-builder based forms instead', false, {
        id: 'form.validated-input',
        for: 'components',
        since: '4.5.5',
        until: 'not defined'
      }));
    },
    actions: {
      /**
       * Trim whitespace from fields
       */
      trimField: function trimField() {
        this.set('value', this.get('value').trim());
      },

      /**
       * Trigger function 'onFileClicked' when file is clicked
       */
      fileClicked: function fileClicked() {
        this.onFileClicked(this.get('file'));
      }
    }
  });

  _exports.default = _default;
});