define("@huvepharma/huvepharma-aviapp-components/templates/components/header/page-editable-header", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "DNxSd7ZP",
    "block": "[[[10,\"header\"],[14,0,\"page-action-header\"],[15,\"data-test-id\",[29,[[36,0],\"-header\"]]],[12],[1,\"\\n      \"],[10,\"h1\"],[15,\"data-test-id\",[29,[[36,0],\"-title\"]]],[12],[1,\"\\n        \"],[1,[28,[35,1],null,[[\"prefix\",\"value\",\"hideSpinner\",\"noSaving\",\"testId\",\"onSave\",\"maxLength\",\"isInlineEditable\"],[[33,2],[33,3],[33,4],[33,5],[28,[37,6],[[33,0],\"-editable-title\"],null],[33,7],[33,8],true]]]],[1,\"\\n\"],[41,[33,10],[[[1,\"            \"],[11,0],[24,0,\"editable-input-icon-wrapper\"],[24,\"data-test-id\",\"header-edit-action\"],[4,[38,11],[[30,0],[33,10]],null],[12],[1,\"\\n                \"],[10,\"svg\"],[14,0,\"editable-input-icon\"],[12],[1,\"\\n                \"],[10,\"use\"],[14,\"xlink:href\",\"#edit-circle\",\"http://www.w3.org/1999/xlink\"],[12],[13],[1,\"\\n                \"],[13],[1,\"\\n            \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"actions\"],[12],[18,1,null],[13],[1,\"\\n\"],[13]],[\"&default\"],false,[\"dataTestId\",\"form/editable-input\",\"editableTitlePrefix\",\"headerTitle\",\"hideSpinner\",\"noSaving\",\"concat\",\"onSave\",\"maxLength\",\"if\",\"editAction\",\"action\",\"yield\"]]",
    "moduleName": "@huvepharma/huvepharma-aviapp-components/templates/components/header/page-editable-header.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});