define("@huvepharma/huvepharma-aviapp-components/components/huve-placeholder-empty", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div data-test-id={{@testId}} class="empty-placeholder">
    <img
      src="/assets/images/{{@image}}"
      alt="{{t @alt}}"
      onerror={{"this.style.display='none'"}}
    />
    <h2>{{t @label}}</h2>
    <div class="add">{{yield}}</div>
  </div>
  */
  {
    "id": "eqg5XBRm",
    "block": "[[[10,0],[15,\"data-test-id\",[30,1]],[14,0,\"empty-placeholder\"],[12],[1,\"\\n  \"],[10,\"img\"],[15,\"src\",[29,[\"/assets/images/\",[30,2]]]],[15,\"alt\",[29,[[28,[37,0],[[30,3]],null]]]],[14,\"onerror\",\"this.style.display='none'\"],[12],[13],[1,\"\\n  \"],[10,\"h2\"],[12],[1,[28,[35,0],[[30,4]],null]],[13],[1,\"\\n  \"],[10,0],[14,0,\"add\"],[12],[18,5,null],[13],[1,\"\\n\"],[13]],[\"@testId\",\"@image\",\"@alt\",\"@label\",\"&default\"],false,[\"t\",\"yield\"]]",
    "moduleName": "@huvepharma/huvepharma-aviapp-components/components/huve-placeholder-empty.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});