define("@huvepharma/huvepharma-aviapp-components/templates/components/form-builder/field-wrapper", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "m/EhSNHI",
    "block": "[[[18,1,[[28,[37,1],null,[[\"label\",\"infoLabel\",\"errors\",\"hasErrors\",\"formElementId\",\"extraInfo\",\"validate\"],[[50,\"form-builder/huve-field-label\",0,null,[[\"label\",\"formElementId\"],[[33,3],[33,4]]]],[50,\"form-builder/huve-field-label\",0,null,[[\"label\",\"class\",\"required\",\"formElementId\"],[[33,5],\"extra-info\",false,[33,4]]]],[50,\"form-builder/huve-field-errors\",0,null,[[\"error\",\"label\",\"prop\"],[[33,6],[33,3],[33,7]]]],[33,8],[33,4],[28,[37,9],[[33,10]],null],[28,[37,11],[[30,0,[\"debounceValidate\"]]],null]]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"component\",\"label\",\"formElementId\",\"infoLabel\",\"error\",\"prop\",\"hasErrors\",\"t\",\"extraInfo\",\"perform\"]]",
    "moduleName": "@huvepharma/huvepharma-aviapp-components/templates/components/form-builder/field-wrapper.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});