define("@huvepharma/huvepharma-aviapp-components/utils/charts/chart-structure", ["exports", "@huvepharma/huvepharma-aviapp-components/utils/charts/visual-chart-helpers"], function (_exports, _visualChartHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  // Various helpers for complex chart functionality, built on top of HighCharts

  /**
   * Because stacked bar charts can expand, contain grouped bars and other nifty features
   * not *technically* supported by HighCharts, we're using some custom structures here.
   * The custom categoryStructure needs to be parsed to result in a highCharts compliant
   * category array, and will be kept throughout the life of this chart to refer to if
   * anything changes dynamically (for example, when clicking an expandable label)
   *
   * For grouped categories:
   * Grouped categories are several horizontal bars under the same label (category)
   * We give highcharts a single label as the first category, and then subsequent empty
   * labels for each bar in that category. Calculating this here avoids overhead and
   * duplicate code and makes the chart object send to the component easier to reason
   * about.
   *
   * For expandable bars:
   * The categoryStructure object maintains the parent/child relations between the bars
   * (or groups of bars) and contains an optional 'hidden' property to toggle the visibility
   * of these bars on and off.
   *
   * @param {Array} categoryStructure
   * @param {Boolean} addPadding
   * @returns {Array} categories
   */
  var parseCategoryStructure = function parseCategoryStructure(categoryStructure, addPadding) {
    return categoryStructure.reduce(function (acc, categoryGroup, index) {
      if (categoryGroup.hidden) {
        return acc;
      }

      var groupItems = categoryGroup.groupItems || 1;

      for (var i = 0; i < groupItems; i++) {
        acc.push(i === 0 ? categoryGroup.name : '');
      } // Add padding row


      if (addPadding && index < categoryStructure.length - 1) {
        acc.push('');
      }

      return acc;
    }, []);
  };
  /**
   * See explanation of parseCategoryStructure as to why we need a custom property and
   * want to parse it to something Highcharts compliant.
   *
   * For grouped categories:
   * Each content item is linked to its categoryId, which may contain several sub bars. In
   * case of sub bars (indicated by a 'subitems' prop on the categoryStructure item), the
   * matching fullContent data object will contain a subItem index to ensure proper placement.
   *
   * For expandable bars:
   * Since each data item is linked by categoryId, it's easy to figure out which should and
   * should not be visible (i.e. added to the result)
   *
   * @param {Array} fullContent
   * @param {Array} categoryStructure
   * @param {Boolean} addPadding
   * @returns {Array} data
   */


  var parseFullContent = function parseFullContent(fullContent, categoryStructure, addPadding) {
    return fullContent.map(function (score) {
      var parsedContentData = score.data.sort(function (a, b) {
        var correspondingACat = categoryStructure.findIndex(function (cat) {
          return cat.id === a.categoryId;
        });
        var correspondingBCat = categoryStructure.findIndex(function (cat) {
          return cat.id === b.categoryId;
        });

        if (correspondingACat === -1 || correspondingBCat === -1) {
          throw new Error('Each content datapoint should correspond to a given category by categoryId');
        }

        if (correspondingACat < correspondingBCat) {
          return -1;
        }

        if (correspondingACat > correspondingBCat) {
          return 1;
        }

        return 0;
      }).reduce(function (acc, datapoint) {
        var matchingCategory = categoryStructure.find(function (cat) {
          return cat.id === datapoint.categoryId;
        });

        if (matchingCategory && !matchingCategory.hidden) {
          (0, _visualChartHelpers.styleChildBars)(matchingCategory, datapoint);
          acc.push(_objectSpread({
            name: datapoint.groupIndex && datapoint.groupIndex > 0 ? '' : matchingCategory.name,
            y: datapoint.value
          }, datapoint.additionalData || {}));

          if (addPadding && (datapoint.groupIndex || datapoint.groupIndex === 0) && datapoint.groupIndex + 1 === matchingCategory.groupItems) {
            // Add an empty padding row datapoint
            acc.push({
              name: '',
              y: null
            });
          }
        }

        return acc;
      }, []);

      if (addPadding) {
        // Remove unnecessary final padding row
        parsedContentData.splice(-1, 1);
      }

      return _objectSpread({
        name: score.name,
        data: parsedContentData
      }, score.additionalData || {});
    });
  };

  var _default = {
    parseCategoryStructure: parseCategoryStructure,
    parseFullContent: parseFullContent
  };
  _exports.default = _default;
});