define("@huvepharma/huvepharma-aviapp-components/templates/components/form-builder/field-errors", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "oIrXTmbt",
    "block": "[[[41,[48,[30,5]],[[[1,\"  \"],[18,5,[[30,1]]],[1,\"\\n\"]],[]],[[[41,[30,1],[[[1,\"  \"],[10,0],[14,0,\"errors\"],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,1,[\"validation\"]]],null]],null],null,[[[44,[[28,[37,6],[\"error-\",[30,3],\"-\",[30,2,[\"type\"]]],null]],[[[41,[30,2,[\"context\",\"uniqueLabels\"]],[[[1,\"          \"],[10,1],[15,\"data-test-id\",[30,4]],[14,0,\"error\"],[12],[1,[28,[35,7],[[30,2,[\"message\"]]],[[\"entity\"],[[28,[37,7],[[30,2,[\"context\",\"entity\"]]],null]]]]],[1,\": \"],[1,[28,[35,8],[[30,0,[\"joinUniqueLabels\"]],[30,2]],null]],[1,\". \"],[13],[1,\"\\n\"]],[]],[[[1,\"          \"],[10,1],[15,\"data-test-id\",[30,4]],[14,0,\"error\"],[12],[1,[28,[35,8],[[30,0,[\"getErrorMessage\"]],[30,2]],null]],[1,\". \"],[13],[1,\"\\n\"]],[]]]],[4]]]],[2]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[]]]],[\"@error\",\"error\",\"@prop\",\"errorTestId\",\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"each\",\"-track-array\",\"let\",\"concat\",\"t\",\"compute\"]]",
    "moduleName": "@huvepharma/huvepharma-aviapp-components/templates/components/form-builder/field-errors.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});