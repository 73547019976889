define("@huvepharma/huvepharma-aviapp-components/helpers/or", ["exports", "@ember/component/helper", "@ember/array", "@ember/object"], function (_exports, _helper, _array, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.or = or;
  _exports.default = void 0;

  function truthConvert(result) {
    var truthy = result && (0, _object.get)(result, 'isTruthy');

    if (typeof truthy === 'boolean') {
      return truthy;
    }

    if ((0, _array.isArray)(result)) {
      return (0, _object.get)(result, 'length') !== 0;
    }

    return !!result;
  }

  function or(params) {
    for (var i = 0, len = params.length; i < len; i++) {
      if (truthConvert(params[i]) === true) {
        return params[i];
      }
    }

    return params[params.length - 1];
  }

  var _default = (0, _helper.helper)(or);

  _exports.default = _default;
});