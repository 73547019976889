define("@huvepharma/huvepharma-aviapp-components/templates/components/search-input", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "39ruvDA+",
    "block": "[[[10,1],[14,0,\"span-icon-search\"],[12],[1,\"\\n  \"],[10,\"svg\"],[12],[10,\"use\"],[14,\"xlink:href\",\"#search\",\"http://www.w3.org/1999/xlink\"],[12],[13],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,\"input\"],[15,2,[30,1]],[15,\"data-test-id\",[30,2]],[15,\"placeholder\",[28,[37,0],[[30,3]],null]],[15,\"oninput\",[28,[37,1],[[30,0],\"onUpdate\"],[[\"value\"],[\"target.value\"]]]],[15,\"onkeydown\",[28,[37,1],[[30,0],\"onKeyDown\"],null]],[15,\"aria-label\",[28,[37,0],[[30,3]],null]],[12],[13],[1,\"\\n\"],[10,1],[14,\"role\",\"button\"],[15,0,[28,[37,2],[\"span-icon-cancel\",[52,[30,1],\" is-visible\"]],null]],[15,\"onclick\",[28,[37,1],[[30,0],\"onClear\"],null]],[12],[1,\"\\n  \"],[10,\"svg\"],[12],[10,\"use\"],[14,\"xlink:href\",\"#cancel\",\"http://www.w3.org/1999/xlink\"],[12],[13],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@value\",\"@testId\",\"@placeholder\"],false,[\"t\",\"action\",\"concat\",\"if\"]]",
    "moduleName": "@huvepharma/huvepharma-aviapp-components/templates/components/search-input.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});