define("ember-composable-helpers/utils/is-promise", ["exports", "@ember/utils", "ember-composable-helpers/utils/is-object"], function (_exports, _utils, _isObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isPromise;

  function isPromiseLike() {
    var obj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return (0, _utils.typeOf)(obj.then) === 'function' && (0, _utils.typeOf)(obj.catch) === 'function';
  }

  function isPromise(obj) {
    return (0, _isObject.default)(obj) && isPromiseLike(obj);
  }
});