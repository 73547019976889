define("@huvepharma/huvepharma-aviapp-components/components/form/range-slider", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div ...attributes>
    {{yield}}
  </div>
  */
  {
    "id": "US21CcQD",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[18,2,null],[1,\"\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "@huvepharma/huvepharma-aviapp-components/components/form/range-slider.hbs",
    "isStrictMode": false
  });

  var RangeSlider = (0, _templateOnly.default)();

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, RangeSlider);

  _exports.default = _default;
});