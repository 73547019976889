define("@huvepharma/huvepharma-aviapp-components/templates/components/form/button-group", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "FUMcfCHE",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[33,2]],null]],null],null,[[[41,[33,4],[[[1,\"    \"],[10,\"button\"],[15,0,[29,[[52,[28,[37,5],[[30,1,[\"value\"]],[33,6]],null],\"active\"]]]],[15,\"data-test-id\",[29,[[30,1,[\"testId\"]]]]],[12],[1,[30,1,[\"title\"]]],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[11,\"button\"],[16,0,[29,[[52,[28,[37,5],[[30,1,[\"value\"]],[33,6]],null],\"active\"]]]],[16,\"data-test-id\",[29,[[30,1,[\"testId\"]]]]],[4,[38,7],[[30,0],\"onClick\",[30,1]],null],[12],[1,[30,1,[\"title\"]]],[13],[1,\"\\n\"]],[]]]],[1]],null],[1,\"\\n\"],[41,[33,8],[[[1,\"  \"],[10,\"label\"],[12],[1,[34,8]],[13],[1,\"\\n\"]],[]],null]],[\"opt\"],false,[\"each\",\"-track-array\",\"options\",\"if\",\"disabled\",\"eq\",\"value\",\"action\",\"label\"]]",
    "moduleName": "@huvepharma/huvepharma-aviapp-components/templates/components/form/button-group.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});