define("@huvepharma/huvepharma-aviapp-components/templates/components/unit-select", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "MbMkSi5O",
    "block": "[[[10,\"select\"],[15,\"onchange\",[28,[37,0],[[30,0],\"setSelection\"],[[\"value\"],[\"target.value\"]]]],[14,0,\"unit-selector\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[33,3]],null]],null],null,[[[1,\"        \"],[10,\"option\"],[15,\"selected\",[52,[28,[37,5],[[30,1,[\"value\"]],[33,6,[\"selected\"]]],null],\"selected\",false]],[15,2,[30,1,[\"value\"]]],[12],[1,[30,1,[\"translated\"]]],[13],[1,\"\\n\"]],[1]],null],[13]],[\"opt\"],false,[\"action\",\"each\",\"-track-array\",\"options\",\"if\",\"eq\",\"field\"]]",
    "moduleName": "@huvepharma/huvepharma-aviapp-components/templates/components/unit-select.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});