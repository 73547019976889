define("ember-concurrency/-private/external/scheduler/policies/bounded-policy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

  var BoundedPolicy = function BoundedPolicy(maxConcurrency) {
    _classCallCheck(this, BoundedPolicy);

    this.maxConcurrency = maxConcurrency || 1;
  };

  var _default = BoundedPolicy;
  _exports.default = _default;
});