define("@huvepharma/huvepharma-aviapp-components/mixins/apply-classname", ["exports", "@ember/object/mixin", "@ember/object"], function (_exports, _mixin, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    init: function init() {
      this._super.apply(this, arguments);

      var applyClassName = (0, _object.get)(this, 'applyClassName');
      var grid = (0, _object.get)(this, applyClassName);

      if (grid) {
        (0, _object.set)(this, 'classNames', (0, _object.get)(this, 'classNames').concat(grid));
      }
    }
  });

  _exports.default = _default;
});