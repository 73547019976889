define("@huvepharma/huvepharma-aviapp-components/components/toggle/toggle-visible", ["exports", "@ember/component", "@ember/object", "@huvepharma/huvepharma-aviapp-components/templates/components/toggle/toggle-visible"], function (_exports, _component, _object, _toggleVisible) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    layout: _toggleVisible.default,
    tagName: '',
    init: function init() {
      this._super.apply(this, arguments);

      var defaultVisible = (0, _object.get)(this, 'defaultVisible');
      var visible = typeof defaultVisible === 'undefined' ? true : defaultVisible;
      (0, _object.set)(this, 'visible', visible);
    },
    actions: {
      toggle: function toggle() {
        (0, _object.set)(this, 'visible', !(0, _object.get)(this, 'visible'));
      }
    }
  });

  _exports.default = _default;
});