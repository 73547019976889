define("@huvepharma/huvepharma-aviapp-components/templates/components/tables/gfr-table-header", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "IcS/YyJt",
    "block": "[[[10,\"tr\"],[14,0,\"gfr-table-header\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[33,2]],null]],null],null,[[[1,\"    \"],[10,\"th\"],[15,\"colspan\",[52,[30,1,[\"children\"]],[30,1,[\"children\",\"length\"]]]],[14,0,\"title\"],[12],[1,\"\\n      \"],[1,[30,1,[\"title\"]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[1]],null],[13],[1,\"\\n\"],[10,\"tr\"],[14,0,\"gfr-table-header\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[33,2]],null]],null],null,[[[41,[30,2,[\"children\"]],[[[42,[28,[37,1],[[28,[37,1],[[30,2,[\"children\"]]],null]],null],null,[[[1,\"        \"],[10,\"th\"],[14,0,\"children\"],[12],[1,\"\\n          \"],[10,1],[12],[1,[30,3,[\"title\"]]],[13],[1,\"\\n          \"],[10,1],[14,0,\"subtitle\"],[12],[1,[30,3,[\"subtitle\"]]],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[3]],null]],[]],[[[1,\"      \"],[10,\"th\"],[14,0,\"subtitle\"],[12],[1,\"\\n        \"],[1,[30,2,[\"subtitle\"]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]]],[1,\"\\n\"]],[2]],null],[13]],[\"field\",\"field\",\"child\"],false,[\"each\",\"-track-array\",\"data\",\"if\"]]",
    "moduleName": "@huvepharma/huvepharma-aviapp-components/templates/components/tables/gfr-table-header.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});