define("@huvepharma/huvepharma-aviapp-components/components/selector/base-selector", ["exports", "@ember/component", "@ember/service", "@ember/object", "@huvepharma/huvepharma-aviapp-components/templates/components/selector/base-selector"], function (_exports, _component, _service, _object, _baseSelector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    layout: _baseSelector.default,
    screen: (0, _service.inject)(),
    rootTestId: 'selector',
    filterHiddenMobile: true,
    isMobileWidth: (0, _object.computed)('screen.isMediumAndDown', function () {
      return !(0, _object.get)(this, 'screen.isMediumAndDown') ? 'close' : 'toggleFilter';
    }),
    actions: {
      close: function close() {
        var _this$close;

        (_this$close = this.close) === null || _this$close === void 0 ? void 0 : _this$close.call(this);
      },
      cancel: function cancel() {
        var _this$cancel;

        (_this$cancel = this.cancel) === null || _this$cancel === void 0 ? void 0 : _this$cancel.call(this);
      },
      overlayClickHandler: function overlayClickHandler() {
        if (this.isCloseableOnOverlayClick) {
          var _this$close2;

          (_this$close2 = this.close) === null || _this$close2 === void 0 ? void 0 : _this$close2.call(this);
        }
      },
      toggleFilter: function toggleFilter() {
        (0, _object.set)(this, 'filterHiddenMobile', this.filterHiddenMobile ? false : true);
      }
    }
  });

  _exports.default = _default;
});