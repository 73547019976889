define("ember-copy/index", ["exports", "ember-copy/copy", "ember-copy/copyable"], function (_exports, _copy, _copyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "copy", {
    enumerable: true,
    get: function get() {
      return _copy.default;
    }
  });
  Object.defineProperty(_exports, "Copyable", {
    enumerable: true,
    get: function get() {
      return _copyable.default;
    }
  });
});